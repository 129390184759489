.GamesEmpty {
  height: 100vh;
  max-width: 1550px;
  margin: 0 auto;
  display: grid;
  justify-content: center;
  align-items: center;


  .noGames {
    color: rgb(255, 255, 255);
    font-size: 40px;
    line-height: 45px;
  }
}

.Games {
  max-width: 1550px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  transition: .4s opacity ease-in-out;

  @media (max-width: 1560px) {
    padding: 0 30px;
  }

  @media (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 20px;
    padding: 0;
  }

  @media (max-width: 480px) {
    margin: 0 10px;
  }

  &[data-visible='true'] {
    opacity: 1;
  }

  &[data-visible='false'] {
    opacity: 0;
  }

}

.containerBtnMore {
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 40px;

  @media screen and (max-width: 800px) {
    // padding: 40px 0 150px 0;
    padding: 50px 0 140px 0;
  }

  @media screen and (max-width: 480px) {
    // padding: 40px 0 150px 0;
    padding: 40px 0;
  }


  .btnMore {
    padding: 12px 40px;
    background: linear-gradient(180deg, #43F174 0%, #03C071 100%);
    border: none;
   border-radius: 8px;
   font-family: 'Nunito Sans', sans-serif;
   font-size: 20px;
   line-height: 28px;
   font-weight: 800;
   color: #122A27;
   cursor: pointer;

   @media screen and (max-width: 800px) {
    font-size: 18px;
   line-height: 26px;
    padding: 10px 20px;
  }

   @media screen and (max-width: 480px) {
    font-size: 15px;
    line-height: 20px;
   }
  }
}
