.containerJackpotsListMobile {
  width: 100%;
  height: 168px;

  position: fixed;
  bottom: 0;
  // z-index: 128;
  z-index: 153;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 801px) {
    display: none;
  }

  @media screen and (max-width: 800px) {
    display: flex;
  }

  .contentJackpotsListMobile {
    display: flex;
    flex-direction: column;
    width: 319px;;
    height: auto;
    margin-top: 40px; // 0
    margin-bottom: 30px;
    justify-content: space-between;
    align-items: center;
    background: radial-gradient(83.71% 107.24% at 49.81% -21.05%, rgba(166, 255, 197, 0.2) 0%, rgba(203, 255, 221, 0) 100%), #184A43;
    box-shadow: 0px 4px 32px rgba(13, 34, 31, 0.24);
    border-radius: 8px;
    text-align: center;

    .openJackpotsListMobile {
      margin: 5px 0;
      width: 28px;
      height: 2px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 2px;
    }

    .contentJackpotsMobile {
      display: none;
    }

    .contentJackpotsMobile[data-active='true'] {
      display: block;
      margin: 20px 0;
      height: 100%;

      .jackpot {
        position: relative;

        @media screen and (max-width: 800px) {  // (max-width: 480px)
          min-width: 280px;
          height: 48px;
          margin: 5px 0;
          background: rgba(7, 192, 166, 0.06);
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.1);
          border-radius: 4px;
        }

        &__name {
          &_iron {
            @media screen and (max-width: 800px) {  // (max-width: 480px
              position: relative;
              transform: rotate(0deg);
              top: initial;
              right: initial;
              order: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          &_silver {
            @media screen and (max-width: 800px) {  //  (max-width: 480px)
              position: relative;
              justify-self: center;
              transform: rotate(0deg);
              top: initial;
              left: initial;
              order: 3;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          &_platinum {
            @media screen and (max-width: 800px) {  // (max-width: 480px)
              position: relative;
              justify-self: center;
              transform: rotate(0deg);
              top: initial;
              right: initial;
              order: 4;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          &_diamond {
            @media screen and (max-width: 800px) {  // (max-width: 480px)
              position: relative;
              justify-self: center;
              transform: rotate(0deg);
              top: initial;
              right: initial;
              order: 5;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          &_gold {
            @media screen and (max-width: 800px) {  // (max-width: 480px)
              position: relative;
              justify-self: center;
              transform: rotate(0deg);
              top: initial;
              right: initial;
              order: 6;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          &_bronze {
            @media screen and (max-width: 800px) {  // (max-width: 480px)
              position: relative;
              justify-self: center;
              transform: rotate(0deg);
              top: initial;
              right: initial;
              order: 2;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

        }

        .jackpotContent {
          display: flex;
          justify-content: center;
          align-items: center;

          @media screen and (max-width: 800px) {  // (max-width: 480px)
            width: 100%;
          }

          .borderJackpot {
            opacity: 0;
          }

          .borderJackpotActivate {
            opacity: 1;
          }

          .textJackpot {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            @media screen and (max-width: 800px) {  // (max-width: 480px)
              position: relative;
              flex-direction: row;
              justify-content: space-around;
              align-items: center;
            }

            .text {
              z-index: 20;
              font-weight: 800;
              font-size: 32px;
              line-height: 44px;
              letter-spacing: 0.1em;
              text-transform: uppercase;

              @media screen and (max-width: 800px) {  // (max-width: 480px)
                font-size: 20px;
                line-height: 28px;
              }
            }

            .iron {
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, #2C2C2C 20.12%, #2C2C2C 20.12%, #FDFDFD 30.18%, #A6A6A6 40.24%, #54514E 46.21%, #FFFFFF 61.3%, #8D8D8D 70.41%, #161616 80.47%);
              -webkit-background-clip: text;
              background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            .bronze {
              top: 10px;
              left: 100px;
              background: linear-gradient(0deg, #7B4729 20.12%, #C88E6E 27.35%, #FEC09C 32.06%, #F19D6D 36.15%, #B56F47 46.21%, #FFC7A7 55.33%, #FFFCFB 61.3%, #FFC09B 68.84%, #C47445 80.47%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }

            .silver {
              background: linear-gradient(0deg, #818181 20.12%, #FDFDFD 29.23%, #6C655B 50.3%, #F6F6F6 70.73%, #BDBDBD 80.47%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }

            .platinum {
              background: linear-gradient(180deg, #CDCDCD 18.34%, #FFFFFF 33.09%, #868686 44.95%, #838383 55.84%, #7C7C7C 67.06%, #D2D2D2 79.88%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }

            .diamond {
              background: conic-gradient(from 90deg at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 46%, rgba(128, 128, 128, 1) 48%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 91%, rgba(128, 128, 128, 1) 94%, rgba(255, 255, 255, 1) 97%, rgba(128, 128, 128, 1) 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }

            .gold {
              background: linear-gradient(0deg, #B38248 20.12%, #C8BA6E 27.35%, #FEFD9C 32.06%, #F1E36D 36.15%, #A67A3B 46.21%, #FFF6A7 55.33%, #FEFFFB 61.3%, #FFF59B 68.84%, #C49545 80.47%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }

            .coinText {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 700;
              font-size: 32px;
              line-height: 44px;
              color: rgb(255, 255, 255);
              text-shadow: 0px 0px 16px rgba(60, 235, 118, 0.5), 0px 0px 32px rgba(60, 235, 118, 0.5);


              @media screen and (max-width: 800px) {  // (max-width: 480px)
                font-size: 20px;
                line-height: 28px;
              }
            }

          }
        }
      }

      @keyframes shadowMobile {

        0% {
          -webkit-filter: drop-shadow(3px 3px 2px rgba(60, 235, 118, .2));
          filter: drop-shadow(0px 0px 16px rgba(60, 235, 118, .2));
        }

        50% {
          -webkit-filter: drop-shadow(3px 3px 2px rgba(60, 235, 118, 1));
          filter: drop-shadow(0px 0px 16px rgba(60, 235, 118, 1));
        }

        100% {
          -webkit-filter: drop-shadow(3px 3px 2px rgba(60, 235, 118, .2));
          filter: drop-shadow(0px 0px 16px rgba(60, 235, 118, .2));
        }
      }

      .active {
        @media screen and (max-width: 800px) {  // (max-width: 480px)
          border: 4px solid #03C071;
          animation: shadowMobile 1s infinite;
          border-radius: 4px;
        }
      }
    }


    .btnImage {
      display: flex;
      justify-self: end;
      width: 231px;
      height: 90px;
      margin-top: -25px;
    }
  }
  
}

.containerJackpotsListMobile[data-active='true'] {
  background: none;
  height: auto;
}
