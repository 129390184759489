.contentProvidersBar {
    background: var(--profileBackground);
    font-family: 'Nunito Sans', sans-serif;
    color: rgb(255, 255, 255);
    margin: 0 auto;
    

    @media screen and (min-width: 1450px) {
        // margin-top: 5%; 
        // margin-left: 30px;
        // margin-right: 30px;
        margin: 10% 30px;
        // max-width: 1450px;
        // width: 1336px;
        padding: 30px;

        // border: 3px solid red;
    }

    @media screen and (max-width: 1450px) {
        // margin-top: 5%; 
        // margin-left: 30px;
        // margin-right: 30px;
        margin: 10% 30px;
        // max-width: 1450px;
        padding: 30px;

        // border: 3px solid magenta;
    }

    @media screen and (max-width: 1024px) {
        // max-width: 1450px;
        padding: 30px;
        margin: 0;
        // padding: 0;
        // border: 3px solid yellowgreen;
    }

    
    @media screen and (max-width: 780px) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        // min-height: 100vh;
        // display: block;
        // width: 100%;
        // margin: 0;
        // padding: 0;
    }

    @media screen and (max-width: 480px) {
        margin: 0;
        padding: 0;
    }

    .backgroundHeader {
        background: #21443E;
        box-shadow: 0px 4px 32px rgba(11, 21, 20, 0.2);

        .mobile {
            @media (min-width: 481px) {
                display: none;
            }

            @media (max-width: 480px) {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                padding: 10px;
                height: 6vh;
            }

            .logo {
                max-width: 130px;
                height: 80%;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }

            .AccountButton {
                .cash {
                    text-shadow: 0px 0px 16px rgba(60, 235, 118, 0.5), 0px 0px 32px rgba(60, 235, 118, 0.5);
                }

                .text {
                    .user_ico {
                        filter: drop-shadow(0px 0px 16px rgba(60, 235, 118, 0.9));
                    }
                }
            }
        }
    }

    .containerButtonClose {
        position: relative;
        display: flex;
        justify-content: flex-end;
        // padding: 10px 10px 0 0;

        @media screen and (max-width: 1024px) { 
            position: relative;
            justify-content: flex-end;
            padding: 10px 10px 0 30px;
        }

        @media screen and (max-width: 480px) {
            position: relative;
            justify-content: flex-start;
            // padding: 10px 10px 0 30px;
            padding: 10px 10px 0 15px;
        }

        .ButtonClose {
            // margin-right: 8px;
            width: 30px;
            height: 30px;
            background: none;
            border: none;

            svg {
                display: inline-block;
                fill: white;
                width: 50%;
                height: auto;
            }

            @media (max-width: 480px) {
                svg {
                    display: none;
                }
                &::after {
                    content: "";
                    width: 30px;
                    height: 30px;
                    background-image: url("./assets/left.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100%;
                  }
            }

            

            // svg {
            //     height: 100%;
            //     width: 100%;
            // }

            // &.ButtonClose {
            //     width: 30px;
            //     height: 30px;
            //     opacity: 0.5;

            //     @media screen and (max-width: 480px) {
            //         display: none;
            //     }
            // }

        //     &.ButtonArrow {
        //         width: 30px;
        //         height: 30px;
        //         font-size: 25px;
        //         font-weight: 800;
        //         opacity: 1;

                // svg {
                //     opacity: 0;
                //     width: 24px;
                //     height: 24px;
                // }
            }

        }
    // }

    .cardProviders {
        width: 100%;
        padding: 0 20px;


        h2 {
            // margin: 10px 0;
            // margin: 0 0 10px 10px;
            margin: 0 0 20px 0;
            font-size: 40px;
            font-weight: 800;
    
            @media screen and (max-width: 1024px) {
                padding-left: 5vw;
            }
    
            @media screen and (max-width: 480px) {
                font-size: 24px;
                line-height: 33px;
                padding-left: 0;
                // padding-left: 40px;
            }
    
            @media screen and (max-width: 380px) {
                // font-size: 24px;
                // line-height: 33px;
                // padding-left: 60px;
                // padding-left: 15%;
            }
        }
    
        .providers {
            display: grid;
            // gap: 20px;
            // display: flex;
            // flex-direction: row;
            // flex-wrap: wrap;
            cursor: pointer;
    
            // уточнить про размер карточек провайдеров при разрешении 990 - 650px
    
            @media screen and (min-width: 1451px) {
                // width: 1332px;
                // width: auto;
                // height: auto;
                grid-template-columns: repeat(5, 1fr);
                gap: 20px;
                // justify-content: flex-start;
                justify-content: space-evenly;
            }
    
            @media screen and (max-width: 1450px) {
                max-width: 1332px;
                overflow-x: hidden;
                height: auto;
                justify-content: center;
                grid-template-columns: repeat(5, 1fr);
                // grid-template-columns: repeat(5, 232px);
                gap: 20px;
            }

            @media screen and (max-width: 1300px) {
                // max-width: 1332px;
                // overflow-x: hidden;
                // height: auto;
                // justify-content: center;
                grid-template-columns: repeat(4, 1fr);
                // grid-template-columns: repeat(5, 232px);
                // gap: 20px;
            }

            @media screen and (max-width: 1100px) {
                // max-width: 1332px;
                // overflow-x: hidden;
                // height: auto;
                // justify-content: center;
                grid-template-columns: repeat(3, 1fr);
                // grid-template-columns: repeat(5, 232px);
                // gap: 20px;
            }
    
            @media screen and (max-width: 1024px) {
                // max-width: 1332px;
                overflow-x: hidden;
                height: auto;
                justify-content: space-evenly;
            }

            @media screen and (max-width: 780px) {
                // overflow-x: hidden;
                // height: auto;
                // justify-content: center;
                grid-template-columns: repeat(2, 1fr);
                // grid-template-columns: repeat(5, 232px);
                // gap: 20px;
            }
    
            @media screen and (max-width: 480px) {
                margin-bottom: 170px;
                // gap: 10px;
                // flex: 1 1;
                justify-content: space-between;
            }
    
            .provider {
                overflow: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                height: auto;
                background: rgba(0, 0, 0, 0.2);
                border-radius: 8px;
                color: rgb(255, 255, 255);
                padding: 20px 0 20px 0;
    
                @media screen and (min-width: 1451px) {
                    width: 232px;
                    // padding: 20px 36px;
                    // margin: 10px;
                }
    
                @media screen and (max-width: 1450px) {
                    max-width: 232px;
                    padding: 20px 36px;
                    margin: 10px;
                }
    
                @media screen and (max-width: 480px) {
                    width: 150px;
                    height: 134px;
                    // width: 18vh;
                    // height: 14vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    margin: 0 auto;
                }
    
                span {
                    font-size: 20px;
                    font-weight: 700px;
    
                }
    
                .label:first-letter {
                    text-transform: uppercase;
                }
    
    
                .label {
                    @media screen and (max-width: 480px) {
                        font-size: 16px;
                        line-height: 22px;
                        font-weight: 700;
                        border-top: 1px solid rgba(0, 0, 0, 0.16);
                    }
                }
    
                .amount {
                    font-weight: 400;
                    font-size: 16px;
                    background: linear-gradient(180deg, #43F174 0%, #03C071 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    -moz-text-fill-color: transparent;
    
                    @media screen and (max-width: 480px) {
                        font-size: 12px;
                        line-height: 17px;
                        font-weight: 400;
                    }
                }
    
                .image {
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
    
                    @media screen and (min-width: 1451px) {
                        width: 160px;
                        height: 80px;
                    }
    
                    @media screen and (max-width: 1450px) {
                        max-width: 160px;
                        // width: 160px;
                    }
    
                    @media screen and (max-width: 480px) {
                        width: 100%;
                        height: 50px;
                        padding-bottom: 5px;
                        // max-width: 80px;
                        // height: 30px;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.04);
                    }
    
                    .imageProvider {
                        max-width: 100%;
                    }
    
                    img {
                        max-width: 160px;
                        max-height: 80px;
                        object-fit: contain;

                        @media screen and (max-width: 480px) {
                            max-width: 80%;
                            height: 48px;
                            object-fit: contain;
                            // border: 1px solid red;
                        }
                    }
    
                }
            }
        }
    }

    .containerJackpots {
        width: 100%;
        height: 168px;

        position: fixed;
        bottom: 0;
        z-index: 28;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, rgba(14, 34, 31, 0) 0%, #0E221F 40.27%);

        @media screen and (min-width: 481px) {
            display: none;
        }

        @media screen and (max-width: 480px) {
            display: flex;
        }
    }

    .containerJackpots[data-active='true'] {
        background: none;
        height: auto;
    }
}