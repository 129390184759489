.categories {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: 0 10px;
    padding: 0;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 66px;
    font-family: 'Nunito Sans', sans-serif;

    .item {
        display: inline-block;
        cursor: pointer;
        @media screen and (min-width: 1201px) {
            flex-direction: column;
        }

        @media screen and (max-width: 1200px) {
            flex-direction: column;
        }

        &[data-active='true'] {
            .label {
                padding: 4px 16px;
                background: linear-gradient(180deg, #43F174 0%, #03C071 100%);
                border-radius: 66px;
                color: var(--footerActiveTextColor);
            }
        }

        &[data-type='slots'] {
            order: 1;
        }

        &[data-type='live-dealers'] {
            order: 2;
        }

        &[data-type='sport-betting'] {
            order: 3;
        }

        &[data-type='jackpots'] {
            // display: none;
            order: 4;
        }

        .icon {
            display: none;
        }

        .label {
            color: var(--footerTextColor);
            text-transform: capitalize;
            opacity: 0.8;
            // font-weight: 700;
            // font-size: 20px;
            text-align: center;

            @media screen and (min-width: 1201px) {
                // font-size: 11px;
                // font-weight: 700; // 500
                // font-size: 1.2vw;
                // line-height: 1.3vw;
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;
                text-align: center;
                padding: 4px 16px;
                margin: 0;
            }

            @media screen and (max-width: 1200px) {
                // font-size: 11px;
                font-weight: 700; // 500
                // font-size: 20px;
                // line-height: 28px;
                font-size: 1.6vw;
                line-height: 2vw;
                padding: 4px 16px;
                margin: 0;
            }
        }
    }
}
