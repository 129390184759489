.lobby {
  position: relative;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;

  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .game-frame {
    padding: 0;
    // margin-top: calc(6vh + 5px);
    margin-top: 10vh;
    // height: calc(100vh - 50px);
    height: calc(100vh - 70px);
    // min-height: 100%;

    @media (min-width: 992px) and (orientation: portrait) {
      position: relative;
      top: -2px;
      height: calc(46.7vh + 2px);
    }
  }

  .background {
    pointer-events: none;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    // background: #184a43;
    background: rgba(33, 68, 62, 1);
    z-index: -5;
    overflow-x: hidden;
    overflow-y: hidden;

    @media screen and (max-width: 1200px) {
      top: 6vh;
    }

    @media screen and (max-width: 1024px) {
      top: 60px;
    }

    .imageBg {
      width: 100%;
      top: 0px;
      height: auto;
      position: absolute;
      z-index: -4;

      @media screen and (max-width: 480px) {
        // display: none;
        height: 100vh;
        filter: blur(7px);
      }

      img {
        width: 100%;
        height: 100%;

        // @media screen and (max-width: 480px) {
        //   display: none;
        // }
      }
    }

    .imageBg2 {
      width: 100%;
      top: 0px;
      height: 100%;
      position: absolute;
      z-index: -3;

      @media screen and (max-width: 480px) {
        display: none;
      }

      .topImage {
        display: flex;
        justify-content: center;

        @media screen and (max-width: 480px) {
          display: none;
        }

        .topImagePalm {
          filter: blur(5px);
        }
      }

      .groupImagesRightTop {
        position: absolute;
        z-index: 50;
        right: 0;
      }

      .LeftTopPalme {
        pointer-events: none;

        @media screen and (max-width: 3840px) {
          width: 544px;
          height: 748px;
          margin-top: -5%;
          margin-left: -300px;
        }

        @media screen and (max-width: 1920px) {
          margin-left: -200px;
        }
        @media screen and (max-width: 480px) {
          display: none;
        }

        .leftTopImagePalm {
          width: 100%;
          height: 100%;
          filter: blur(8px);
          pointer-events: none;
        }
      }
    }

    .imageBg3 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      top: 100px;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -2;

      @media screen and (max-width: 480px) {
        display: none;
      }

      .groupTopImages,
      .groupMiddleImages,
      .groupBottomImages {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        // @media screen and (max-width: 480px) {
        //   display: none;
        // }
      }

      .rotateImage {
        transform: rotate(-65deg);

      }

      .groupTopImages {
        overflow: hidden;

        .coinTop {
          img {

            @media screen and (max-width: 1200px) {
              max-width: 115px;
            }

            @media screen and (max-width: 790px) {
              max-width: 100px;
            }

            @media screen and (max-width: 740px) {
              max-width: 85px;
            }
          }
        }
      }

      .groupMiddleImages {
        .coinMiddle {
          img {
            @media screen and (max-width: 790px) {
              max-width: 115px;
              height: auto;
            }
          }
        }

        .BananaMiddle {
          @media screen and (max-width: 790px) {
            max-width: 200px;
            height: auto;
          }
        }
      }
    }

    .backgroundPalms {
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .left,
      .rigth {
        margin: 0 85px;
      }
    }
  }

  .content {
    // margin: 6vh auto 0 auto; // 0 auto
    margin: 10vh auto 0 auto;

    .containerGames {
      position: relative;
      margin-bottom: 30px;

      .innerImages {
        position: absolute;
        z-index: -5;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .groupImagesRightBottom {
          position: relative;
          display: flex;
          align-items: flex-end;
          justify-content: space-around;
          width: 100%;

          @media screen and (max-width: 3840px) {
            justify-content: space-between;
          }

          .imagePineapple {
            margin-right: 150px;
            transform: rotate(80deg);
            width: 169px;
            height: 293px;
            filter: blur(5px);

            @media screen and (max-width: 650px) {
              max-width: 160px;
              max-height: 180px;
            }

            @media screen and (max-width: 600px) {
              max-width: 140px;
              max-height: 160px;
            }

            @media screen and (max-width: 480px) {
              display: none;
            }
          }

          .rightImagePalm {
            max-width: 326px;
            max-height: 300px;
            filter: blur(5px);

            @media screen and (max-width: 1005px) {
              max-width: 230px;
              max-height: 210px;
            }

            @media screen and (max-width: 860px) {
              max-width: 278px;
              max-height: 210px;
            }

            @media screen and (max-width: 720px) {
              max-width: 260px;
              max-height: 150px;
            }

            @media screen and (max-width: 650px) {
              max-width: 60px;
              max-height: 100px;
            }

            @media screen and (max-width: 480px) {
              display: none;
            }
          }

          .rightImagePalm2 {
            position: absolute;
            width: 168px;
            height: 286px;
            bottom: 20%;
            right: 0;
            transform: scaleX(-1);
            filter: blur(12px);
          }
        }

        .leftImagePalm {
          max-width: 778px;
          max-height: 710px;
          filter: blur(5px);

          @media screen and (max-width: 1150px) {
            max-width: 578px;
            max-height: 510px;
          }


          @media screen and (max-width: 1010px) {
            max-width: 478px;
            max-height: 510px;
          }

          @media screen and (max-width: 860px) {
            max-width: 278px;
            max-height: 210px;
          }

          @media screen and (max-width: 480px) {
            display: none;
          }
        }

        .leftImagePalm2 {
          position: absolute;
          width: 168px;
          height: 286px;
          filter: blur(12px);
        }

      }
    }

  }

  .backgroundJackpotsListButton {
    // position: absolute;
    position: fixed;
    z-index: 152;
    top: 0%;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(11, 12, 12, 0.8);

    @media screen and (min-width: 481px) {
      display: none;
    }

    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  .backgroundJackpotsListButton[data-active='true'] {
    display: block;
  }

  .backgroundJackpotsListButton {
    pointer-events: none;

    @media screen and (max-width: 480px) {
      width: 100%;
      height: 168px;
      position: fixed;
      bottom: 0;
      z-index: 28;
      display: flex;
      background: linear-gradient(180deg, rgba(33, 68, 62, 0) 0%, #21443E 40.27%);
    }
  }

  @keyframes activeArrow {
    0% {
      transform: translateY(0%);
    }

    25% {
      transform: translateY(-20%);
    }

    26% {
      transform: translateY(-20%);
    }

    55% {
      transform: translateY(0%);
    }
  }

  .containerBtnToTop {
    justify-content: flex-end;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 999;

    @media screen and (max-width: 480px) {
      bottom: 100px;
    }

    .btnToTop {
      display: none;

      img {
        animation-name: activeArrow;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }

    }

    .active {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 40px;
      width: 5vh;
      height: 5vh;
      border-radius: 50%;
      background: linear-gradient(180deg, #43F174 0%, #03C071 100%);
      border: none;
      cursor: pointer;
    }
  }



}

//  scrollbar for Google Chrome be inside the block Lobby
.lobby::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

.lobby::-webkit-scrollbar-track {
  background-color: #999;
}

.lobby::-webkit-scrollbar-track-piece {
  background-color: rgba(255, 255, 255, 0.8);
}

.lobby::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #03C071;
  border-radius: 30px;
}

.lobby::-webkit-scrollbar-corner {
  background-color: #999;
}

@-moz-document url-prefix() {

  //  scrollbar for Mozila be inside the block Lobby
  .lobby {
    scrollbar-color: #03C071 rgba(255, 255, 255, 0.8) !important;
    // scrollbar-track-color: ;
    scrollbar-width: thin !important;
  }
}

.lobby[data-active="true"] {
  height: 100vh;
  overflow-y: hidden;
}
