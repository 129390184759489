* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: 0 !important;
    outline-color: transparent !important;
    outline-width: 0 !important;
    outline-style: none !important;
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important;

  }
}

body {
  font-family: var(--fontFamily);
  overflow: hidden;
  user-select: none;
  min-height: calc(var(--vh, 1vh) * 100);

  @media screen and (max-width: 480px) {
    // background-color: rgb(33, 68, 62);
  }

  .WheelFrame {  // добавляет кнопку звука на колесе фриспинов, кнопка по умочанию из компонента не отображается
    .controls {
        .ButtonSound {
          &::after {
              content: "";
              display: block;
              width: 75%;
              height: 75%;
              background-image: url("./assets/sound.svg");
              background-repeat: no-repeat;
              background-position: center;
              background-size: 70%;
            }
        
            &::before {
              content: "";
              display: block;
              width: 80%;
              height: 2px;
              position: absolute;
              transform: rotate(-45deg);
              border-radius: 2px;
              background-color: rgb(252, 16, 16);
              opacity: 0;
              transition: .2s opacity ease;
            }
        
            &[data-mute='true'] {
              &::before {
                opacity: 1;
              }
            }
      } 
    }
  }
}

body.modalWindow [data-visible='true'] {
  overflow: hidden;
}

.Auth {

  &::after {
    background: none;

  }

  @media screen and (max-width: 480px) {
    overflow: hidden;
  }

  .content {
    .window {

      @media screen and (min-width: 480px) {
        background: rgb(18, 42, 39);
      }

      @media screen and (max-width: 480px) {
        background: none;
        border: none;
        backdrop-filter: blur(0);
        box-shadow: none;
      }

      .top {
        justify-content: flex-end;

        .name,
        .ButtonSound {
          display: none;
        }
      }

      .forms {

        .intl-tel-input, .input {
          @media screen and (max-width: 480px) {
            background: rgba(0, 0, 0, 0.4);
            border-radius: 4px;
          }
        }
        .submit-button {
          border-radius: 100px;
          background: var(--submitButtonBackground);
          box-shadow: var(--submitButtonShadow);
          border-radius: 8px;
          font-weight: 800;
        
          &::after {
            border-radius: 100px;
          }

          span {
            color: var(--submitButtonTextColor);
          }
        }
      }
    }

    .download {
      .link {
        background: linear-gradient(180deg, #43F174 0%, #03C071 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        border: 2px solid #03C071;
        border-radius: 8px;
        transition: .4s background-color ease;
        padding: 6px 32px;
      }
    }

  }
}

.Button {
  // кнопки на форме автоизации звук и языки
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: .3s;
  box-sizing: content-box;
  border-radius: 6px;
  background: var(--buttonBackground);
  border: var(--buttonBorder);

  &:hover {
    background: var(--buttonHoverBackground);
    border: var(--buttonHoverBorder);
  }
}

.input {
  // общие стили инпутов формы авторизации
  border: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.2);
  color: var(--inputTextColor);
  font-size: 18px;
  font-family: var(--fontFamily);
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;

  &::-webkit-input-placeholder {
    color: var(--inputTextColor);
    opacity: .4;
  }

  &::-moz-placeholder {
    color: var(--inputTextColor);
    opacity: .4;
  }

  &:-ms-input-placeholder {
    color: var(--inputTextColor);
    opacity: .4;
  }

  &:-moz-placeholder {
    color: var(--inputTextColor);
    opacity: .4;
  }

  &:focus {
    border: var(--inputFocusBorder);
  }
}

.InputPassword {
  .visible {
    opacity: 0.5;

    &::after {
      background: var(--inputTextColor);

    }
  }
}

#root {
  height: 100vh;
}

.view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: var(--bodyBackground);
}

.view>.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;
  height: 100%;
}

.grecaptcha-badge {
  display: none !important;
}

.core-bonuses-info .bonuses-values>p:not(.active),
.core-bonuses-info .bonuses-statuses>p:not(.active),
.bonuses-info p:not(.active) {
  display: none;
}

.wheel.active {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;

  iframe {
    width: 100vw;
    height: 50vh;
  }
}

