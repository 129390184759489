.AccountButton {
  position: relative;
  display: flex;
  height: 100%;
  font-family: 'Nunito Sans', sans-serif;
  color: var(--accountButtonTextColor);
  font-size: 1.2vw;
  line-height: 1.3vw;
  cursor: pointer;

  @media (max-width: 991px) {
    font-size: 14px;
    padding: 0 10px;
    line-height: 16px;
    // height: 30px;
    height: 10vh;
  }

  @media (max-width: 600px) {
    height: 30px;
  }

  @media (max-width: 480px) {
    padding: 0;
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 100px;
    box-shadow: var(--accountButtonLight);
    opacity: 0;
    transition: .3s opacity ease;
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    padding: 0 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 1px 0 rgba(255, 255, 255, 0.08), 0 0 0 rgba(255, 255, 255, 0.08);

    @media (max-width: 950px) {
      padding: 0 10px;
    }

    @media (max-width: 900px) {
      padding: 0 10px;
      border: none;
      box-shadow: none;
    }

    @media (max-width: 480px) {
      padding: 0 0 0 5px;
    }

    // @media (max-width: 390px) {
    //   padding: 0 0 0 5px;
    // }

    .user_ico {
      width: 1.3vw;
      height: 1.3vw;

      @media (max-width: 991px) {
        height: 13px;
        width: 13px;
      }

      @media (max-width: 480px) {
        height: 24px;
        width: 24px;
      }
    }

    .user {
      @media (max-width: 600px) {
        display: none;
      }
    }

    .signin {
      @media (max-width: 991px) and (orientation: portrait) {
        display: none;
      }

    }

    .default {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .default[data-active='true'] {
      opacity: 0;
    }

    .account {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      padding: 0 20px 0 25px;
      z-index: 200;
      column-gap: 5px;

      .user {
        background: linear-gradient(180deg, #43F174 0%, #03C071 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -moz-text-fill-color: transparent;

        @media (max-width: 480px) {
          display: none;
        }
      }

      .user_ico {

        @media (max-width: 578px) {
          display: none;
        }
      }

      .user_ico svg path {
        fill: pink;
      }
    }
  }

  .cash {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    // padding: 0 20px;
    padding: 0 30px;
    border-left: 1px solid rgba(0, 0, 0, 0.16);
    border-right: 1px solid rgba(0, 0, 0, 0.16);
    // box-shadow: 1px 0 0 rgba(255, 255, 255, 0.08);
    box-shadow: 1px 0 rgba(255, 255, 255, 0.08), -1px 0 0 rgba(255, 255, 255, 0.08);

    .currency {
      padding-left: 5px;
    }

    @media (max-width: 950px) {
      padding: 0 10px;
    }

    @media (max-width: 900px) {
      border-left: none;
      box-shadow: none;
      padding-right: 10px;
    }

    @media (max-width: 600px) {
      border: none;
      box-shadow: none;
      padding-right: 0;
    }

    @media screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }
  }

  // .cash::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: rgba(0, 0, 0, 0.5); /* Здесь можно изменить цвет и степень затемнения */
  //   z-index: 1;
  // }
}