.containerJackpotsList {
  position: relative;
  display: block;
  max-width: 100%;
  transition: .2s opacity ease;

  @media  (max-width: 3840px) {
    height: 780px;
  }

  @media (max-width: 1420px) {
    height: 775px;
  }

  @media (max-width: 1340px) {   //
    height: calc(370px + (390 - 50) * ((100vw - 600px) / (1238 - 600)));
  }

    @media screen and (max-width: 800px) {
    display: none;
  }

  &[data-visible='true'] {
    opacity: 1;
  }

  &[data-visible='false'] {
    opacity: 0;
  }

  .jackpotsWrapper {
    display: flex;
    justify-content: center;
    height: 100%;
    pointer-events: none;

    @media screen and (max-width: 480px) {
      position: relative;
    }

    .containerImageJackpots {
      width: 100%;
      position: absolute;
      top: 50px;
      display: flex;
      justify-content: center;
      pointer-events: none;

      @media screen and (max-width: 1450px) {
        width: 95%;
      }

      .circleImage {
        max-width: 95%;

        @media screen and (max-width: 1450px) {
          max-width: 95%;
          height: auto;
        }
      }

      @media screen and (max-width: 480px) {
        display: none;
      }
    }

    .containerImageLianes {
      width: 100%;
      position: absolute;
      top: 50px;
      display: flex;
      justify-content: center;
      z-index: 10;

      .lianesImage {
        max-width: 100%;
        margin-left: 100px;
        margin-top: -40px;
        pointer-events: none;

        @media screen and (max-width: 1450px) {
          max-width: 100%;
          margin-left: 7%;
          margin-top: -3%;
        }
      }

      @media screen and (max-width: 480px) {
        display: none;
      }
    }

    .containerLogoImage {
      width: 100%;
      position: absolute;
      top: 300px;
      display: flex;
      justify-content: center;
      z-index: 11;

      img {
        width: 100%;
      }

      @media screen and (max-width: 3840px) {
        width: 797px;
        height: auto;
      }

      @media screen and (max-width: 1250px) {
        width: 50%;
      }

      @media screen and (max-width: 850px) {
        top: 250px;
      }

      @media screen and (max-width: 768px) {
        top: 200px;

      }

      @media screen and (max-width: 600px) {
        top: 170px;
      }

      @media screen and (max-width: 480px) {
        display: none;
      }
    }

    .containerImageBird {
      position: absolute;
      z-index: 12;
      top: 250px;
      right: 0;
      pointer-events: none;

      @media screen and (max-width: 1340px) {
        top: 290px;
      }

      @media screen and (max-width: 1250px) {
        top: 240px;
      }

      @media screen and (max-width: 991px) {
        top: 200px;
      }

      @media screen and (max-width: 780px) {
        top: 170px;
      }

      @media screen and (max-width: 600px) {
        top: 150px;
      }

      .imageBird {
        max-width: 400px;
        max-height: 590px;
        pointer-events: none;

        @media screen and (max-width: 1340px) {
          max-width: 300px;
          max-height: 490px;
        }

        @media screen and (max-width: 1200px) {
          max-width: 230px;
          max-height: 390px;
        }

        @media screen and (max-width: 991px) {
          max-width: 200px;
          max-height: 360px;
        }

        @media screen and (max-width: 780px) {
          max-width: 180px;
          max-height: 320px;
        }

        @media screen and (max-width: 640px) {
          max-width: 150px;
          max-height: 250px;
        }

        @media screen and (max-width: 600px) {
          max-width: 120px;
          max-height: 220px;
        }
      }
    }


    .containerJacpots {
      position: absolute;
      z-index: 2;
      width: 100%;
      margin: 50px auto auto 0;

      @media screen and (min-width: 1450px) {
        width: 1238px;
        height: 100%;
      }

      @media screen and (max-width: 1450px) {
        width: 95%;
        height: 95%;
      }

      @media screen and (max-width: 480px) {
        position: relative;
        margin: 0;
        width: 100%;
      }

      .jackpots {
        position: relative;
        display: grid;
        margin: 0 auto;
        width: 95%;
        height: 95%;

        @media screen and (max-width: 1450px) {
          width: 90%;
          height: 93%;
        }

        @media screen and (max-width: 1420px) {
          width: 93%;
          height: 93%;
        }

        @media screen and (max-width: 1400px) {
          width: 92%;
          height: 92%;
        }

        @media screen and (max-width: 1380px) {
          width: 92%;
          height: 90%;
        }

        // https://vc.ru/dev/178033-dinamicheskoe-masshtabirovanie-elementov-v-css

        .jackpot {
          position: absolute;
          display: grid;
          width: 30%;

          @media screen and (max-width: 480px) {
            min-width: 280px;
            height: 48px;
            margin: 5px 0;
            background: rgba(7, 192, 166, 0.06);
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2), inset 0px 1px 1px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
          }

          &__name {
            &_iron {
              display: inline-block;
              order: 5;
              transform: rotate(-86deg);

              @media screen and (max-width: 3840px) {
                bottom: 8%;
                left: -9%;
              }

              @media screen and (max-width: 1450px) {
                bottom: 2%;
                left: -7%;
              }

              @media screen and (max-width: 1380px) {
                bottom: 0.5%;
                left: -8%;
              }

              @media screen and (max-width: 1360px) {
                bottom: 3%;
                left: -7.5%;

                @media screen and (max-width: 950px) {
                  bottom: 4%;
                  left: -7.5%;
                }
              }

              @media screen and (max-width: 860px) {
                bottom: 7%;
                left: -7.5%;
              }

              @media screen and (max-width: 760px) {
                bottom: 8.5%;
                left: -7.5%;
              }
            }

            &_silver {
              display: inline-block;
              order: 3;
              transform: rotate(-53deg); // 51

              @media screen and (max-width: 3840px) {
                bottom: 47%;
                left: 0.5%;
              }

              @media screen and (max-width: 1450px) {
                bottom: 44%;
                left: 2%;
              }

              @media screen and (max-width: 1380px) {
                bottom: 43%;
                left: 1.5%;
                transform: rotate(-51deg);
              }

              @media screen and (max-width: 950px) {
                bottom: 45%;
                left: 1.2%;
              }

              @media screen and (max-width: 860px) {
                bottom: 46%;
                left: 2%;
              }

              @media screen and (max-width: 760px) {
                bottom: 47.5%;
                left: 1.5%;
              }
            }

            &_platinum {
              display: inline-block;
              order: 1;
              transform: rotate(-16deg);

              @media screen and (max-width: 3840px) {
                top: 4.5%;
                left: 22%;
              }

              @media screen and (max-width: 1450px) {
                top: 4.5%;
                left: 23%; //22.5
              }

              @media screen and (max-width: 950px) {
                top: 4.5%;
                left: 22%;
              }

              @media screen and (max-width: 860px) {
                top: 4.5%;
                left: 22.5%;
              }


            }

            &_diamond {
              display: inline-block;
              order: 2;
              transform: rotate(18deg);

              @media screen and (max-width: 3840px) {
                top: 4.5%;
                right: 22%;
              }

              @media screen and (max-width: 1450px) {
                top: 4.5%;
                right: 23%;
              }

              @media screen and (max-width: 1420px) {
                top: 5%;
                right: 22.5%;
              }

              @media screen and (max-width: 950px) {
                top: 5%;
                right: 22.5%;
              }

              @media screen and (max-width: 860px) {
                top: 5%;
                right: 22.5%;
              }
            }

            &_gold {
              display: inline-block;
              order: 4;
              transform: rotate(53deg);

              @media screen and (max-width: 3840px) {
                bottom: 47%;
                right: 0.5%;
              }

              @media screen and (max-width: 1450px) {
                bottom: 44%;
                right: 2%;
              }

              @media screen and (max-width: 1380px) {
                bottom: 43%;
                right: 1.5%;
                transform: rotate(51deg);
              }


              @media screen and (max-width: 950px) {
                bottom: 45%;
                right: 1.2%;
              }

              @media screen and (max-width: 860px) {
                bottom: 46%;
                right: 2%;
              }

              @media screen and (max-width: 760px) {
                bottom: 47.5%;
                right: 1.5%;
              }
            }

            &_bronze {
              display: inline-block;
              order: 6;
              transform: rotate(86deg);

              @media screen and (max-width: 3840px) {
                bottom: 8%;
                right: -9%;
              }

              @media screen and (max-width: 1450px) {
                bottom: 2.5%;
                right: -8%;
              }

              @media screen and (max-width: 950px) {
                bottom: 5%;
                right: -8.5%;
              }

              @media screen and (max-width: 860px) {
                bottom: 7%;
                right: -7.5%;
              }

              @media screen and (max-width: 760px) {
                bottom: 8.5%;
                right: -7.5%;
              }
            }

          }

          .jackpotContent {
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 480px) {
              width: 100%;
            }

            .borderJackpot {
              opacity: 0;
            }

            @keyframes shadow {
              0% {
                -webkit-filter: drop-shadow(3px 3px 2px rgba(60, 235, 118, .2));
                filter: drop-shadow(0px 0px 16px rgba(60, 235, 118, .2));
              }

              50% {
                -webkit-filter: drop-shadow(3px 3px 2px rgba(60, 235, 118, 1));
                filter: drop-shadow(0px 0px 16px rgba(60, 235, 118, 1));
              }

              100% {
                -webkit-filter: drop-shadow(3px 3px 2px rgba(60, 235, 118, .2));
                filter: drop-shadow(0px 0px 16px rgba(60, 235, 118, .2));
              }
            }

            .borderJackpotActivate {
              opacity: 1;
              animation: shadow 1s infinite;
              background: none;
            }

            .image {
              height: auto;
              background: none;

              @media screen and (min-width: 1450px) {
                width: calc(125px + (350 - 160) * ((100vw - 600px) / (1238 - 600))); // 140
              }

              @media screen and (max-width: 1450px) {
                // width: calc(125px + (350 - 175) * ((100vw - 600px) / (1238 - 600))); // 140
                width: calc(125px + (350 - 175) * ((100vw - 600px) / (1238 - 600)));
              }

              @media screen and (max-width: 1380px) {
                width: calc(125px + (350 - 168) * ((100vw - 600px) / (1238 - 600)));
              }

              @media screen and (max-width: 1320px) {
                width: calc(125px + (350 - 160) * ((100vw - 600px) / (1238 - 600)));
              }

              @media screen and (max-width: 1280px) {
                width: calc(125px + (350 - 170) * ((100vw - 600px) / (1238 - 600))); // 180
              }

              @media screen and (max-width: 1200px) {
                width: calc(125px + (350 - 160) * ((100vw - 600px) / (1238 - 600))); // 180
              }

              @media screen and (max-width: 1060px) {
                width: calc(125px + (350 - 150) * ((100vw - 600px) / (1238 - 600))); // 160
              }

              @media screen and (max-width: 1024px) {
                width: calc(125px + (350 - 140) * ((100vw - 600px) / (1238 - 600)));
              }

              @media screen and (max-width: 760px) {
                width: calc(125px + (350 - 105) * ((100vw - 600px) / (1238 - 600)));
              }

              @media screen and (max-width: 680px) {
                width: calc(135px + (300 - 10) * ((100vw - 600px) / (1238 - 600)));
              }

              @media screen and (max-width: 640px) {
                width: calc(145px + (350 - 10) * ((100vw - 600px) / (1238 - 600)));
              }

              @media screen and (max-width: 600px) {
                width: calc(145px + (350 - 220) * ((100vw - 600px) / (1238 - 600)));
              }

              @media screen and (max-width: 480px) {
                display: none;
              }
            }

            .textJackpot {
              position: absolute;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100%;

              @media screen and (max-width: 480px) {
                position: relative;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
              }

              .text {
                z-index: 20;
                font-weight: 800;
                font-size: 32px;
                line-height: 44px;
                letter-spacing: 0.1em;
                text-transform: uppercase;

                @media screen and (max-width: 991px) {
                  font-size: 27px;
                  line-height: 32px;
                }

                @media screen and (max-width: 950px) {
                  font-size: 25px;
                  line-height: 30px;
                }

                @media screen and (max-width: 850px) {
                  font-size: 20px;
                  line-height: 22px;
                }

                @media screen and (max-width: 768px) {
                  font-size: 17px;
                  line-height: 19px;
                }

                @media screen and (max-width: 640px) {
                  font-size: 15px;
                  line-height: 17px;
                }

                @media screen and (max-width: 600px) {
                  font-size: 15px;
                  line-height: 17px;
                }

                @media screen and (max-width: 480px) {
                  font-size: 20px;
                  line-height: 28px;
                }
              }

              .iron {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, #2C2C2C 20.12%, #2C2C2C 20.12%, #FDFDFD 30.18%, #A6A6A6 40.24%, #54514E 46.21%, #FFFFFF 61.3%, #8D8D8D 70.41%, #161616 80.47%);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
              }

              .bronze {
                top: 10px;
                left: 100px;
                background: linear-gradient(0deg, #7B4729 20.12%, #C88E6E 27.35%, #FEC09C 32.06%, #F19D6D 36.15%, #B56F47 46.21%, #FFC7A7 55.33%, #FFFCFB 61.3%, #FFC09B 68.84%, #C47445 80.47%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -moz-text-fill-color: transparent;
              }

              .silver {
                background: linear-gradient(0deg, #818181 20.12%, #FDFDFD 29.23%, #6C655B 50.3%, #F6F6F6 70.73%, #BDBDBD 80.47%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -moz-text-fill-color: transparent;
              }

              .platinum {
                background: linear-gradient(180deg, #CDCDCD 18.34%, #FFFFFF 33.09%, #868686 44.95%, #838383 55.84%, #7C7C7C 67.06%, #D2D2D2 79.88%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -moz-text-fill-color: transparent;
              }

              .diamond {
                background: conic-gradient(from 90deg at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 46%, rgba(128, 128, 128, 1) 48%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 91%, rgba(128, 128, 128, 1) 94%, rgba(255, 255, 255, 1) 97%, rgba(128, 128, 128, 1) 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -moz-text-fill-color: transparent;
              }

              .gold {
                background: linear-gradient(0deg, #B38248 20.12%, #C8BA6E 27.35%, #FEFD9C 32.06%, #F1E36D 36.15%, #A67A3B 46.21%, #FFF6A7 55.33%, #FEFFFB 61.3%, #FFF59B 68.84%, #C49545 80.47%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -moz-text-fill-color: transparent;
              }

              .coinText {
                font-family: 'Nunito Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 44px;
                color: rgb(255, 255, 255);
                text-shadow: 0px 0px 16px rgba(60, 235, 118, 0.5), 0px 0px 32px rgba(60, 235, 118, 0.5);

                @media screen and (max-width: 991px) {
                  font-size: 27px;
                  line-height: 32px;
                }

                @media screen and (max-width: 950px) {
                  font-size: 25px;
                  line-height: 30px;
                }

                @media screen and (max-width: 850px) {
                  font-size: 20px;
                  line-height: 22px;
                }

                @media screen and (max-width: 768px) {
                  font-size: 17px;
                  line-height: 19px;
                }

                @media screen and (max-width: 640px) {
                  font-size: 15px;
                  line-height: 17px;
                }

                @media screen and (max-width: 600px) {
                  font-size: 15px;
                  line-height: 17px;
                }

                @media screen and (max-width: 480px) {
                  font-size: 20px;
                  line-height: 28px;
                }
              }

            }
          }
        }

        .active {
          @media screen and (max-width: 480px) {
            border: 4px solid #03C071;
            border-radius: 4px;
          }
        }

      }
    }
  }
}

.containerJackpotsListGame {
  // width: 100%;   // растягвает блок на всю доступную ширину
  height: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.078);
  border-left: 1px solid rgba(0, 0, 0, 0.161);

  .jackpotsWrapperGame {
    position: relative;
    // display: flex;
    // justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;

    .containerJacpotsGame {
      position: relative;
      width: 100%;
      height: 100%;

      .jackpotsGame {
        display: flex;
        // justify-content: space-evenly;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        @media (max-width: 1024px) {
          flex-direction: column;
          justify-content: normal;
          height: auto;
          background: rgb(18, 42, 39);
          border: 2px solid rgb(18, 42, 39);
          border-radius: 10px;
          padding: 10px 0;
        }


        .jackpotGame {
          // display: inline-block;
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 0 10px;
          // height: 100%;
          // border: 1px solid red;
          border-left: 1px solid rgba(255, 255, 255, 0.078);
          border-right: 1px solid rgba(0, 0, 0, 0.161);

          @media (max-width: 1024px) {
            width: 90%;
            // height: 48px;
            height: 4.5vw;
            margin: 10px 0;
            padding: 0;
            border-radius: 7px;
            background: rgba(7, 192, 166, 0.06);
            box-shadow: 0px 1px 1px 0px rgba(255, 255, 255, 0.1) inset;
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);

            // border: 2px solid magenta;
          }

          @media (max-width: 480px) {
            height: 50px;
          }

          .jackpotContentGame {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 7px;
            background: linear-gradient(180deg, #23493F 0%, #0D654C 100%);


            @media (min-width: 1921px) {
              width: 162px;
              height: 76px;
            }

            @media (max-width: 1920px) {
              width: 8.7vw;
              height: 4.1vw;
            }

            @media (max-width: 1200px) {
              width: 9.5vw;
              height: 4.5vw;
            }

            @media (max-width: 1024px) {
              width: 100%;
              // height: 4.5vw;
              height: auto;
            }

            @keyframes shadow {
              0% {
                -webkit-filter: drop-shadow(3px 3px 2px rgba(60, 235, 118, .2));
                filter: drop-shadow(0px 0px 16px rgba(60, 235, 118, .2));
              }

              50% {
                -webkit-filter: drop-shadow(3px 3px 2px rgba(60, 235, 118, 1));
                filter: drop-shadow(0px 0px 16px rgba(60, 235, 118, 1));
              }

              100% {
                -webkit-filter: drop-shadow(3px 3px 2px rgba(60, 235, 118, .2));
                filter: drop-shadow(0px 0px 16px rgba(60, 235, 118, .2));
              }
            }

            .borderJackpotGame {
              width: 0;
              height: 0;
              opacity: 0;
            }

            .borderJackpotGameActivate {
              position: absolute;
              z-index: 3;
              top: 0;
              width: 100%;
              height: 100%;
              opacity: 1;
              animation: shadow 1s infinite;
              background: none;

              @media (max-width: 1024px) {
                display: none;
              }
            }

            img {
              max-width: 100%;
              height: auto;
            }

            .textJackpotGame {
              @media (max-width: 1024px) {
                display: flex;
                flex-direction: row;
                width: 90%;
                justify-content: space-between;
              }

              .textGame {
                display: flex;
                flex-direction: row;
                justify-content: center;
                font-weight: 800;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: 10%;
                text-transform: uppercase;

                @media (max-width: 1500px) {
                  // font-size: 17px;
                  // line-height: 25px;
                  font-size: 1.3vw;
                  line-height: calc(1.5vw + 0.1vw);
                }

                @media (max-width: 480px) {
                  font-size: 5.3vw;
                  line-height: 4.6vw;
                }

              }

              .iron {
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, #2C2C2C 20.12%, #2C2C2C 20.12%, #FDFDFD 30.18%, #A6A6A6 40.24%, #54514E 46.21%, #FFFFFF 61.3%, #8D8D8D 70.41%, #161616 80.47%);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;
              }

              .bronze {
                top: 10px;
                left: 100px;
                background: linear-gradient(0deg, #7B4729 20.12%, #C88E6E 27.35%, #FEC09C 32.06%, #F19D6D 36.15%, #B56F47 46.21%, #FFC7A7 55.33%, #FFFCFB 61.3%, #FFC09B 68.84%, #C47445 80.47%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -moz-text-fill-color: transparent;
              }

              .silver {
                background: linear-gradient(0deg, #818181 20.12%, #FDFDFD 29.23%, #6C655B 50.3%, #F6F6F6 70.73%, #BDBDBD 80.47%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -moz-text-fill-color: transparent;
              }

              .platinum {
                background: linear-gradient(180deg, #CDCDCD 18.34%, #FFFFFF 33.09%, #868686 44.95%, #838383 55.84%, #7C7C7C 67.06%, #D2D2D2 79.88%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -moz-text-fill-color: transparent;
              }

              .diamond {
                background: conic-gradient(from 90deg at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 46%, rgba(128, 128, 128, 1) 48%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 91%, rgba(128, 128, 128, 1) 94%, rgba(255, 255, 255, 1) 97%, rgba(128, 128, 128, 1) 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -moz-text-fill-color: transparent;
              }

              .gold {
                background: linear-gradient(0deg, #B38248 20.12%, #C8BA6E 27.35%, #FEFD9C 32.06%, #F1E36D 36.15%, #A67A3B 46.21%, #FFF6A7 55.33%, #FEFFFB 61.3%, #FFF59B 68.84%, #C49545 80.47%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                -moz-text-fill-color: transparent;
              }

              .coinTextGame {
                display: flex;
                justify-content: center;
                font-family: 'Nunito Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;
                color: rgb(255, 255, 255);
                text-shadow: 0px 0px 16px rgba(60, 235, 118, 0.5), 0px 0px 32px rgba(60, 235, 118, 0.5);

                @media (max-width: 1500px) {
                  // font-size: 17px;
                  font-size: 1.1vw;
                  line-height: calc(1.5vw + 0.1vw);
                }

                @media (max-width: 480px) {
                  font-size: 5.3vw;
                  line-height: 4.6vw;
                }
              }
            }
          }
        }

        .active {
          @media screen and (min-width: 1025px) {
            border: none;
          }

          @media screen and (max-width: 1024px) {
            border: 4px solid #03C071;
            border-radius: 10px;
            animation: shadow 1s infinite;
          }
        }
      }
    }
  }
}
