.title-bonus {
    display: flex;
    align-items: center;
    position: relative;

    span.text-title-bonus {
        font-weight: 600 !important;
        font-size: 18px;
        line-height: 24px;
    }

    .btn-info {
        position: relative;
        background: none;
        margin: 0 5px;
        padding: 4px 0 0 0;
        border: none;
        cursor: pointer;

        .btn-tooltip {
            opacity: 0.4;
        }

        .btn-tooltip[data-active='true'] {
            opacity: 1;
        }
    }

    .btn-info[data-active='true']::after {
        display: block;
        position: absolute;
        bottom: -10px;
        left: 0;
        content: "";
        width: 0;
        height: 0;
        border-bottom: 12px solid #0B1B19;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
    }

    .btn-info::after {
        display: none;
    }

    .popupInfo {
        display: none;
        position: absolute;
        z-index: 2;
        top: 35px;

        .triangle {
            margin: 0 45%; // подправить расстояние от края
            width: 0;
            height: 0;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-bottom: 12px solid #0B1B19;
        }

        .textContent {
            padding: 16px;
            background: #0B1B19;
            border-radius: 8px;
            color: rgb(255, 255, 255);
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
        }
    }

    .popupInfo[data-active='true'] {
        display: block;
    }
}

.progressLine {
    width: 100%;
    display: inline-block;
    margin: 10px 0;

    progress {
        width: 100%;
        margin-left: 0;
        -webkit-appearance: none;
    }

    progress[value]::-webkit-progress-bar {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 66px;
        width: 100%;
        height: 8px;
    }

    progress[value]::-webkit-progress-value {
        background-image: linear-gradient(180deg, #43F174 0%, #03C071 100%);
    }

    .valueBonuses {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;


        .min,
        .max {
            display: inline-block;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: rgb(255, 255, 255);
        }

        .min {
            background: linear-gradient(180deg, #43F174 0%, #03C071 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -moz-text-fill-color: transparent;
        }
    }
}