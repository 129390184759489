.balanceTab {
  display: flex;
  flex-direction: column;
  font-family: 'Nunito Sans', sans-serif;

  .containerContetnt {

    .containerBalance {
      padding: 0 45px;

      .fio,
      .login,
      .balance {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;

        .title {
          font-weight: 700;
          font-size: 18px;
          line-height: 28px;
          color: var(--profileTextColor);

          @media (max-width: 640px) {
            font-size: 15px;
          }
        }

        @media (max-width: 640px) {
          font-size: 15px;
        }

        @media (max-width: 569px) {
          font-size: 15px;
        }

        .value {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--profileTextColor);

          @media (max-width: 640px) {
            font-size: 15px;
          }

          @media (max-width: 569px) {
            font-size: 15px;
          }
        }

        .edit {
          display: none;
        }
      }

      .row {
        display: flex;
        padding: 10px 0;

        &_info {
          font-size: 14px;
          font-weight: 500;
          color: var(--profileTextColor);
        }
      }
    }

    .information {
      color: var(--profileTextColor);
      padding-top: 20px;
      // overflow-y: auto;
      // height: 300px;
      height: auto;

      .bonuses-info {
        display: flex;
        width: 100%;
        padding: 0 30px;

        .bonus-item {
          // margin-bottom: 50px;
          background-color: transparent;

          .PromoForm {
            .flex {

              @media (max-width: 569px) {
                display: flex;
                flex-direction: column;
              }

              .input {
                font-family: var(--fontFamily);
                background: var(--promoFormInputBackground);
                color: var(--promoFormTextColor);
                box-shadow: var(--promoFormInputShadow);

                &::placeholder {
                  color: var(--promoFormTextColor);
                }

                @media (max-width: 569px) {
                  margin: 0;
                  width: 100%;
                }
              }

              .submit {
                background: var(--promoFormSubmitButtonBackground);
                color: var(--promoFormSubmitButtonTextColor);

                &:hover {
                  background-color: var(--promoFormInputActiveBackground);
                }

                @media (max-width: 569px) {
                  margin-top: 20px;
                  width: 100%;
                }
              }
            }
          }

          .text {
            span {
              // font-weight: 400;
              // font-size: 15px;
              font-weight: 400;
              font-size: 15px;
              line-height: 24px;
            }

            b {
              font-weight: 700;
              font-size: 15px;
              line-height: 24px;
              color: #43F174;
            }
          }

          .progress {
            width: 100%;
          }
        }

      }

    }

    //  .information
    .information::-webkit-scrollbar {
      width: 5px;
      height: 3px;
    }

    .information::-webkit-scrollbar-track {
      background-color: #999;
    }

    .information::-webkit-scrollbar-track-piece {
      background-color: rgba(255, 255, 255, 0.8);
    }

    .information::-webkit-scrollbar-thumb {
      height: 30px;
      background-color: #03C071;
      border-radius: 30px;
    }

    .information::-webkit-scrollbar-corner {
      background-color: #999;
    }

    @-moz-document url-prefix() {
      .information {
        scrollbar-color: #03C071 rgba(255, 255, 255, 0.8) !important;
        scrollbar-width: thin !important;
      }
    }

    .containerButtonLanguages {
      max-width: 150px;
      display: flex;
      flex-direction: row;
      margin-left: 45px;
      color: rgb(255, 255, 255);

      @media screen and (min-width: 481px) {
        display: none;
      }

      span {
        margin-right: 20px;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
      }

      .ButtonLanguages {
        background: none;
        border: none;

        .list {
          z-index: 130;
        }
      }
    }

    .containerButtons {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      .contanerButton {
        display: flex;
        justify-content: flex-start;
        margin-top: auto;
        margin-bottom: 5px;
        padding-left: 35px;

        .download {
          margin-bottom: 20px;
          margin-left: 10px;

          @media (max-width: 480px) {
            width: 160px;
          }

          .link {
            padding: 10px 20px;
            margin-top: 15px;
            background: none;
            border: 2px solid #03C071;
            border-radius: 8px;
            transition: .2s ease;
            color: var(--profileSectionDownloadTextColor);

            .text {
              font-weight: 600;
              font-size: 12px;
              line-height: 16px;
              background: linear-gradient(180deg, #43F174 0%, #03C071 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              -moz-text-fill-color: transparent;
            }

            &::after {
              box-shadow: var(--profileSectionDownloadShadow);
            }

            &:hover {
              background-color: var(--profileSectionDownloadBackground);
            }
          }
        }
      }

    }
  }
}