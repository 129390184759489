.popup_jackpots {
  left: 50%;
  bottom: 50%;
  padding: 0;
  max-width: 390px;
  border-radius: 10px;
  overflow: hidden;
  height: 620px;
  transform: translate(-50%, 50%);
  box-shadow: 0px 5px 90px rgba(0, 0, 0, 1);
  margin-bottom: 30px;
  display: flex;
  align-items: flex-start;
  padding-left: 10px;
  flex-direction: column;
  justify-content: center;
  background: radial-gradient(73% 121.18% at 47.67% 91.38%, rgba(56, 25, 105, 0.67) 0%, rgba(8, 7, 25, 0.67) 100%);

  @media (max-width: 992px) {
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    height: 100%;
    max-width: 100%;
    transform: none;
    margin: 0;
  }
  @media (max-width: 992px) and (orientation: landscape) {
    &.popup {
      display: block;
      overflow: scroll;

      .logo {
        display: block;
        margin: 30px auto 20px auto;
      }

      .jackpots-list {
        padding-top: 30px;
      }
    }
  }

  .logo {
    max-width: 200px;
    width: 100%;
    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.3));
    margin: 0 auto 50px auto;

    @media (max-width: 340px) and (orientation: portrait) {
      max-width: 180px;
    }
  }

  .Button {
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 2;
    top: 15px;
    right: 15px;
  }

  .jackpots-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .jackpot {
      min-width: 240px;
      margin-bottom: 20px;
      height: 60px;
      padding: 0 20px;
      background: linear-gradient(180deg,rgba(62, 24, 143, 1) 45%,rgba(50, 0, 120, 1) 55%);
      box-shadow: inset 0px 0px 20px rgba(255, 255, 255, .6), 0px 0px 2px rgba(255, 255, 255, .3);
      border-radius: 45px;

      &.active {
        background: linear-gradient(180deg, rgba(255, 211, 98, 0.9) 0%, rgba(206, 159, 40, 0.9) 48.96%, rgba(197, 157, 56, 0.9) 100%);
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 45px;
          animation-name: pulse;
          animation-duration: 1.3s;
          animation-timing-function: ease;
          animation-direction: alternate-reverse;
          animation-iteration-count: infinite;
          position: absolute;
          box-shadow: 0px 0px 20px rgba(255, 255, 255, .6);
        }
      }

      @media (max-width: 340px) {
        height: 50px;
        min-width: 200px;
        margin-bottom: 10px;
      }

      &__name {
        height: 23px;
        width: 100%;
      }

      &__val {
        font-size: 20px;
        text-shadow: rgba(0, 0, 0, .6) 1px 0 10px;
      }
    }
  }
}
