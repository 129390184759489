@mixin controls($content, $width) {
    position: relative;
    justify-content: $content;
    width: $width;
}


.header {
    position: fixed;
    background: var(--headerBackground);
    width: 100%;
    height: 10vh;
    z-index: 99;
    box-shadow: 0px 4px 64px 0px rgba(11, 21, 20, 0.2);

    @media screen and (max-width: 480px) {
        background: var(--headerMobileBackground);
        background: rgba(33, 68, 62, 1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    }

    &[data-visible='true'] {
        opacity: 1;
        right: 5px;
    }

    &[data-visible='false'] {
        opacity: 0;
    }

    .container {
        width: 100%;
        display: flex;
        height: 10vh;

        &[data-active="true"] {
            @media screen and  (min-width: 1201px) {
                max-width: 100% !important;
                padding: 0 30px;
                // border: 2px solid red;
            }

            @media screen and (max-width: 1200px) {
                padding: 0 30px;

            }

            @media screen and (max-width: 480px) {
                padding: 0 10px;
            }
        }

        @media screen and (min-width: 1201px) {
            flex-direction: row;
            max-width: 1550px;
            margin: 0 auto;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
        }

        @media screen and (max-width: 1200px) {
            flex-direction: row;
            max-width: 1920px;
            margin: 0 auto;
            justify-content: space-around;
            align-items: center;
        }

        @media screen and (max-width: 1024px) {
            flex-direction: row;
            max-width: 1920px;
            margin: 0 auto;
            justify-content: space-around;
            align-items: center;
        }

        @media screen and (max-width: 900px) {
            justify-content: space-between;
        }

        @media screen and (max-width: 600px) {
            flex-direction: row;
            max-width: 1920px;
            margin: 0 auto;
            justify-content: space-between;
            padding: 0 20px;
            align-items: center;
        }

        .logo {
            cursor: pointer;

            @media screen and (min-width: 1201px) {
                max-width: 100%;
                height: 100%;
                margin-left: 30px;
            }

            @media screen and (max-width: 1200px) {
                max-width: 100%;
                margin-left: 30px;
            }

            @media screen and (max-width: 1024px) {
                max-width: 100%;
            }

            @media screen and (max-width: 600px) {
                max-width: 130px;
                height: 80%;
            }

            @media screen and (max-width: 600px) {
                display: grid;
                align-items: center;
            }

            img {
                @media screen and (min-width: 1201px) {
                    // max-height: 9vh;
                    height: 9vh;
                }

                @media screen and (max-width: 1200px) {
                    // max-height: 5vh;
                    height: 7vh;
                }

                @media screen and (max-width: 600px) {
                    max-width: 100%;
                    height: auto;
                }
            }

            .containerActiveImage {
                display: none;
            }
        }

        .containerCategoriesList {
            display: flex;
            width: 100%;

            @media screen and (min-width: 1201px) { align-items: center; }

            @media screen and (max-width: 1200px) { align-items: center; }

            @media screen and (max-width: 1024px) { align-items: center; }

            @media screen and (max-width: 900px) {
                display: none;
            }


        }

        .containerCategoriesList[data-active="true"]::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(11, 12, 12, 0.8); /* Здесь можно изменить цвет и степень затемнения */
            z-index: 1;
          }

        .controls {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;

            @media (min-width: 1921px) {
                @include controls(space-evenly, auto);
            }

            @media (max-width: 1920px) {
                @include controls(flex-end, 40%); // space-evenly, 100%
            }

            @media (max-width: 1200px) {
                @include controls(flex-end, 70vw);
            }

            @media (max-width: 1024px) and (orientation: landscape) {
                @include controls(flex-end, 80vw);
            }

            @media (max-width: 990px) and (orientation: landscape) {
                @include controls(flex-end, 40vw);
            }

            @media (max-width: 990px) and (orientation: portrait) {
                @include controls(flex-end, 70vw);
                // border: 1px solid red;

            }


            .jackpotsHeader[data-games="true"] {
                display: flex;
                width: 100%;
                height: 100%;
                // border: 2px solid blue;
                // display: none;

                @media (max-width: 1024px) {
                    position: relative;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                }

                .jackpotsHeaderTitle {
                    color: rgb(255, 255, 255);
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 28px;
                    text-align: center;
                    cursor: pointer;

                    @media (min-width: 1025px) {  //1201px
                        display: none;
                    }

                    @media (max-width: 1024px) {  // 1200px
                        display: inline-block;
                        max-width: 450px;
                        margin: 0 auto;
                        // margin-bottom: 5px;
                    }

                    @media (max-width: 800px) {
                        max-width: 50vw;
                    }

                    @media (max-width: 480px) {
                        margin-top: 5px;
                        margin-bottom: 0;
                        max-width: 180px;
                        font-size: 18px;
                        line-height: 20px;
                    }

                    @media (max-width: 380px) {
                        margin-top: 3px;
                        font-size: 16px;
                        line-height: 20px;
                        max-width: 150px;
                    }

                    @media (max-width: 320px) {
                        font-size: 14px;
                        line-height: 20px;
                        max-width: 110px;
                    }
                }

                .contentJackpotsHeader {
                    // width: 80vw;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    border-radius: 10px;
                    width: 100%;
                    height: 100%;


                    @media (max-width: 1024px) {
                        position: absolute;
                        bottom: -100%;
                        display: none;
                        // max-width: 450px;
                        width: 350px;
                    }

                    @media (max-width: 480px) {
                        position: fixed;
                        top: 10vh;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        display: none;
                        width: 100%;
                        background: rgb(0, 0, 0, 0.8);
                    }


                }

                .contentJackpotsHeader[data-active="true"] {
                    display: inline;
                }
            }

            .Button {
                margin-left: 8px;
                width: 30px;
                height: 30px;
                background: none;
                border: none;

                @media (max-width: 991px) {
                    width: 30px;
                    height: 30px;
                }
            }

            .ButtonLanguages {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 30px;

                @media screen and (max-width: 1500px) {
                    margin: 0 10px;
                }

                @media screen and (max-width: 900px) {
                    display: none;
                }

                .trigger {
                    .flag {
                        img {
                            width: 24px;
                            height: 24px;
                            border-radius: 6px;
                        }
                    }
                }

                .trigger::after {
                    content: url('./assets/down.png');
                    background-size: 20px 15px;
                    display: flex;
                    align-items: center;
                    background-position: bottom 1px right 1px;
                }

                .list {
                    background: rgba(27, 56, 52, 0.9);
                }
            }

            .game {
                width: auto;
                display: inline-block;
            }

            .ButtonLanguages .list {
                z-index: 85;
            }

            .ButtonClose {
                svg {
                    display: inline-block;
                    fill: white;
                    width: 50%;
                    height: auto;
                }
            }

            .ButtonClose::after {
                content: "";
                background-image: url("./assets/closeBtn.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100%;
            }

            // }
        }


        .controls[data-games="true"] {
            width: 100%;
        }
    }
}

.header[data-game="true"] {
    background: linear-gradient(180deg, #2E5A54 0%, #1B3834 100%);
    box-shadow: 0px 4px 64px 0px #0B151426;
    right: 0;

    .container {

        .logo {


            img {
                max-width: 202px;
                max-height: 86px;
                margin-right: 30px;

                @media screen and (max-width: 480px) {
                    max-width: 100%;
                    height: auto;
                    margin-right: 0;
                }
            }

        }

        .controls {
            @media (max-width: 1920px) {
                @include controls(flex-end, 100%); // space-evenly, 100%
            }
        }
    }
}
