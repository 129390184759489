@import "@devvs/shared/dist/styles/breakpoints.scss";

.spin-jackpots-list {
  display: flex;
  height: 100%;

@media (max-width: 1200px) {
    display: inline-block;
}

  &-hiding {
    transition: opacity 0.6s ease-out;
    transition-delay: 0.4s;
    opacity: 0 !important;
  }

  .spin-jackpot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 1;

    @media (min-width:  1921px) {
      padding: 5px;
      background: rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 1920px) {
      padding: 0 5px;
      background: rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 1200px) {
    margin: 20px 0;
    flex-direction: row;
    background: none;
}

    &__name {
      display: flex;
      font-weight: 700;
      font-family: 'Nunito Sans';
      text-transform: uppercase;

      @media (min-width: 1921px) {
        font-size: 28px;
        line-height: 30px;
        margin-right: 20px;
      }

      @media (max-width: 1920px) {
        font-size: 18px;
        line-height: 20px;
      }

      @media (max-width: 1301px) {
        font-size: 16px;
        line-height: 18px;
        margin-right: 10px;
      }

      @media (max-width: 1200px) {
        font-size: 18px;
        line-height: 24px;
      }

      @media (max-width: 320px) {
        font-size: 16px;
        line-height: 20px;
    }
    }

    &__digit_text {
      font-family: 'Nunito Sans';
      font-style: normal;
      text-align: center;
      font-weight: 700;
      color: rgb(255, 255, 255);
      text-shadow: 0px 0px 16px rgba(60, 235, 118, 0.5), 0px 0px 32px rgba(60, 235, 118, 0.5);

      @media (max-width: 320px) {
        font-size: 14px;
        line-height: 20px;
    }
    }

    &_diamond {

      .spin-jackpot__name {
        height: 100%;
        background: conic-gradient(from 90deg at 50% 50%, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 46%, rgba(128, 128, 128, 1) 48%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 91%, rgba(128, 128, 128, 1) 94%, rgba(255, 255, 255, 1) 97%, rgba(128, 128, 128, 1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -moz-text-fill-color: transparent;
      }

      .spin-jackpot__digit {

        &_text {
          display: flex;
          align-items: flex-end;
          justify-content: center;
        }
      }
    }

    &_platinum {
      .spin-jackpot__name {
        height: 100%;
        background: linear-gradient(180deg, #CDCDCD 18.34%, #FFFFFF 33.09%, #868686 44.95%, #838383 55.84%, #7C7C7C 67.06%, #D2D2D2 79.88%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -moz-text-fill-color: transparent;
      }

      .spin-jackpot__digit {
        &_text {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          color: #FFFFFF;
        }
      }
    }

    &_gold {
      .spin-jackpot__name {
        height: 100%;
        background: linear-gradient(0deg, #B38248 20.12%, #C8BA6E 27.35%, #FEFD9C 32.06%, #F1E36D 36.15%, #A67A3B 46.21%, #FFF6A7 55.33%, #FEFFFB 61.3%, #FFF59B 68.84%, #C49545 80.47%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -moz-text-fill-color: transparent;
      }

      .spin-jackpot__digit {
        &_text {
          display: flex;
          align-items: flex-end;
          justify-content: center;
        }
      }
    }

    &_silver {
      .spin-jackpot__name {
        height: 100%;
        background: linear-gradient(0deg, #818181 20.12%, #FDFDFD 29.23%, #6C655B 50.3%, #F6F6F6 70.73%, #BDBDBD 80.47%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -moz-text-fill-color: transparent;
      }

      .spin-jackpot__digit {
        &_text {
          display: flex;
          align-items: flex-end;
          justify-content: center;
        }
      }
    }

    &_bronze {
      .spin-jackpot__name {
        height: 100%;
        background: linear-gradient(0deg, #7B4729 20.12%, #C88E6E 27.35%, #FEC09C 32.06%, #F19D6D 36.15%, #B56F47 46.21%, #FFC7A7 55.33%, #FFFCFB 61.3%, #FFC09B 68.84%, #C47445 80.47%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -moz-text-fill-color: transparent;
      }

      .spin-jackpot__digit {
        &_text {
          display: flex;
          align-items: flex-end;
          justify-content: center;
        }
      }
    }

    &_iron {
      .spin-jackpot__name {
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, #2C2C2C 20.12%, #2C2C2C 20.12%, #FDFDFD 30.18%, #A6A6A6 40.24%, #54514E 46.21%, #FFFFFF 61.3%, #8D8D8D 70.41%, #161616 80.47%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .spin-jackpot__digit {
        &_text {
          display: flex;
          align-items: flex-end;
          justify-content: center;
        }
      }
    }


    &_diamond.active,
    &_platinum.active {
      animation: jackpotPulse 1s infinite linear;

      .spin-jackpot__digit_text {
        text-shadow: 0px 0px 3vh rgba(255, 199, 0, 0.7), 0px 0.3vh 9vh rgba(255, 122, 0, 0.6);
        animation: pulse 1s infinite ease-in-out;
      }
    }

    &_gold.active,
    &_silver.active {
      animation: jackpotPulse 1s infinite linear;

      .spin-jackpot__digit_text {
        text-shadow: 0px 0px 0.8vh rgba(255, 199, 0, 0.7), 0px 0.16vh 2.63vh rgba(255, 122, 0, 0.6);
        animation: pulse 1.5s infinite ease-in-out;
      }
    }

    &_bronze.active,
    &_iron.active {
      animation: jackpotPulse 1s infinite linear;

      .spin-jackpot__digit_text {
        text-shadow: 0px 0px 0.68vh rgba(255, 199, 0, 0.7), 0px 0.13vh 2.2vh rgba(255, 122, 0, 0.6);
        animation: pulse 1.5s infinite ease-in-out;
      }
    }
  }
}

@keyframes moveX {
  100% {
    background-position: 100%;
  }
}

@keyframes pulse {
  from {
    opacity: .1;
  }

  to {
    opacity: 1;
  }
}


@keyframes jackpotPulse {
  0% {
    transform: rotate(-2deg) scale(1)
  }

  10% {
    transform: scale(1.005) rotate(1deg)
  }

  25% {
    transform: scale(1.010) rotate(-1deg)
  }

  30% {
    transform: scale(1.015) rotate(1deg)
  }

  40% {
    transform: scale(1.020) rotate(-1deg)
  }

  50% {
    transform: scale(1.025) rotate(1deg)
  }

  60% {
    transform: scale(1.020) rotate(-1deg)
  }

  70% {
    transform: scale(1.015) rotate(1deg)
  }

  80% {
    transform: scale(1.010) rotate(-1deg)
  }

  90% {
    transform: scale(1.005) rotate(1deg)
  }

  100% {
    transform: rotate(0deg) scale(1)
  }
}

@keyframes jackpotFadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}