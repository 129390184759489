.list-panel-block {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    // max-width: 90%;
    // width: 90%;
    color: #fff;
    font-weight: 700;
    position: relative;  
    gap: 5px;

    @media (max-width: 1200px) {
        max-width: 100%;
        width: 100%;
        height: 100%;
        min-height: 300px;
        display: block;
    }

    .spin-jackpot {
        display: flex;
        // flex-direction: row;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
}