.modalWindow {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: var(--modalWindowBackground);
  // z-index: 100;
  transition: .2s opacity ease;

  &[data-visible='true'] {
    opacity: 1;
    z-index: 100;
    display: grid;
    justify-content: center;
    align-items: center;


    //  значение устанавливается чтобы блок не уползал под фиксированную шапку
    @media (max-width: 3840px) {
      top: 0;
    }
    @media (max-width: 1024px) {
      top: 10vh;
    }

    @media (max-width: 578px) {
      // top: 6vh;
      top: 10vh;
    }
  }

  &[data-visible='false'] {
    opacity: 0;
    pointer-events: none;
    z-index: 0;
  }

  @media (max-width: 3840px) {
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 1920px) {
    justify-content: stretch;
    align-items: center;
  }

  @media (max-width: 578px) {
    background-color: transparent;
  }
}