.wrapper-types-buttons {
  @media screen and (max-width: 820px) {
    backdrop-filter: blur(10px);
  }

  // @media screen and (max-width: 480px) {
  // backdrop-filter: blur(10px);
  // }

  .types-buttons {
    display: flex;
    max-width: 950px;
    align-items: center;
    border-radius: 0;

    @media screen and (max-width: 800px) {
      justify-content: center;
    }

    @media screen and (max-width: 480px) {
      max-width: 1350px;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      justify-content: stretch;
    }

    &__button {
      position: relative;
      display: flex;
      flex-basis: content;
      justify-content: center;
      align-items: center;
      background-repeat: no-repeat;
      color: var(--typesButtonsTextColor);
      opacity: 0.5;
      font-family: 'Nunito Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      text-transform: capitalize;
      cursor: pointer;

      @media (min-width: 1921px) {
        //  and (orientation: portrait)
        margin: 20px;
        font-size: 0.7vw;
      }


      @media (max-width: 1920px) {
        //  and (orientation: portrait)
        margin: 20px;
        font-size: 1.3vw;
      }

      @media screen and (max-width: 1320px) {
        margin: 20px;
        font-size: 1.5vw;
      }

      // @media screen and (max-width: 1080px) {
      //   margin: 10px;
      // }

      @media screen and (max-width: 1024px) {
        margin: 10px;
        font-size: 1.7vw;
      }

      @media screen and (max-width: 800px) {
        font-size: 2vw;
      }

      @media screen and (max-width: 480px) {
        display: flex;
        font-size: 3.5vw;
        padding: 15px 20px 0 20px;
      }

      span {
        @media screen and (max-width: 480px) {
          display: inline-block;
          text-align: center;
          max-width: 130px;
        }
      }


      &::after {
        content: "";
        width: 100%;
        height: 74%;
        position: absolute;
        bottom: 0;
        opacity: 0;
        transition: .2s opacity ease;
      }

      &.active::after {
        opacity: 1;
      }

      &.active {
        opacity: 1;
        border-bottom: 2px solid #03C071;
        border-radius: 1px;

        span {
          text-shadow: 0px 0px 16px rgba(60, 235, 118, 0.5), 0px 0px 32px rgba(60, 235, 118, 0.5);
        }
      }

      &.active,
      &:hover {
        color: var(--typesButtonsActiveTextColor);
      }
    }
  }

  .types-buttons :last-child {
    margin-right: 0;
  }

  .types-buttons::-webkit-scrollbar {
    // scrollbar-width: thin;
    // 
    opacity: 0;
    // opacity: 1;
  }
}