.popup_bonuses {
  left: 50%;
  border: 1px solid gold;
  bottom: 50%;
  padding: 0;
  max-width: 390px;
  border-radius: 10px;
  overflow: hidden;
  height: 620px;
  transform: translate(-50%, 50%);
  box-shadow: 0px 5px 90px rgba(0, 0, 0, 1);
  margin-bottom: 30px;

  @media (max-width: 992px) {
    top: 50%;
    bottom: auto;
    width: calc(100% - 16px);
    left: 50%;
    border-radius: 10px;
    height: calc(var(--vh, 1vh) * 100 - 16px);
    transform: translateX(-50%) translateY(-50%);
    margin-bottom: 0;
  }

  @media (min-width: 992px) and (orientation: portrait) {
    max-width: 800px;
    height: 1240px;

    .bonuses .bonus .wrapper .get-info-button {
      margin-bottom: 60px;
      width: 190px;
      min-height: 73px;
      max-height: 73px;
      font-size: 24px;
    }

    .bonus .wrapper .info .title {
      font-size: 48px;
    }
    .bonus .wrapper .info .text {
      font-size: 28px;
      line-height: 40px;
    }
  }

  @media (max-width: 991px) and (orientation: landscape) {
    max-width: 100%;
  }

  .button {
    position: absolute;
    z-index: 2;
    top: 15px;
    right: 15px;
  }
}

@keyframes slideDown {
  0% {
    top: -300vh;
  }
  100% {
    top: 0px;
  }
}
