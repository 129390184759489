.backgroundBlured {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  pointer-events: none; 
  
    .imageBg {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -4;
      pointer-events: none;

      @media screen and (max-width: 480px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
  
      img {
        width: 100%;
        height: 100%;

        @media screen and (max-width: 480px) {
        width: 1920px;
        }
      }
    }
  
    .imageBg2 {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -3;
  
      .topImage {
        display: flex;
        justify-content: center;

        @media screen and (max-width: 480px) {
        display: none;
        }
  
        .topImagePalm {
          filter: blur(5px);
        }
      }
  
      .groupImagesRightTop {
        display: flex; 
        justify-content: flex-end;

        @media screen and (max-width: 480px) {
        display: none;
        }
      }
  
      .innerImages {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
  
        .groupImagesRightBottom {
          display: flex; 
          align-items: flex-end; 
          justify-content: space-around;
        }
  
        .leftImagePalm {
          max-width: 778px;
          max-height: 710px;
          filter: blur(5px);

          @media screen and (max-width: 480px) {
        display: none;
          }
        }
  
        .imagePineapple {
          margin-right: 150px;
          margin-bottom: -110px;
          transform: rotate(80deg);
          width: 169px;
          height: 215px;
          height: 293px;
          filter: blur(5px);
          pointer-events: none;
        }
  
        .rightImagePalm {
          max-width: 326px;
          max-height: 300px;
          filter: blur(5px);
          pointer-events: none;

          @media screen and (max-width: 480px) {
        display: none;
          }
        }
      }
    }
  
    .imageBg3 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -2;

      @media screen and (max-width: 480px) {
       display: none; 
      }
  
      .groupTopImages, .groupMiddleImages, .groupBottomImages {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        
      }
  
      .rotateImage {
        transform: rotate(-65deg);
      }
  
    }
  
    .backgroundPalms {
      width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: -1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      pointer-events: none;

      @media screen and (max-width: 480px) {
       display: none; 
      }
    
  
      .left, .rigth {
        margin: 0 85px;
        pointer-events: none;

        img {
          pointer-events: none;
        }
      } 
    }
  }
  