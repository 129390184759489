.info-from-core {
  color: #fff;
  max-height: 70vh;
  width: 80vw;
  overflow-y: scroll;
  @media (max-width: 992px) {
    width: 95vw;
    height: calc(100vh - 140px);
    max-height: calc(100vh - 140px);
  }
}
.popup_info {
  max-height: 70vh;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  @media (max-width: 992px) {
    top: 53px;
    width: 100vw;
    left: 0;
    border-radius: 0;
    height: calc(100vh - 53px);
    animation: slideDown 1s forwards;
  }
  .button {
    position: absolute;
    top: 25px;
    right: 35px;
  }
}

.info-from-core::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.info-from-core::-webkit-scrollbar-track {
  background: linear-gradient(
    to right,
    transparent 40%,
    #000 40%,
    #000 60%,
    transparent 60%
  );
  width: 7px;
}

.info-from-core::-webkit-scrollbar-thumb {
  background: linear-gradient(to right, #ffe731, #cc7f03);
}

@keyframes slideDown {
  0% {
    top: -300vh;
  }
  100% {
    top: 53px;
  }
}
