.popup {
  position: fixed;
  background-color: var(--profileBackground);
  backdrop-filter: blur(4px);
  z-index: 100;
  padding: 0;
  display: flex;
  flex-direction: column;
  transition: .3s opacity ease;

  &[data-visible='true'] { 
    opacity: 1;
  }

  &[data-visible='false'] {
    opacity: 0;
    pointer-events: none;
    display: none;
  }

  &_fullscreen {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    @media (max-width: 992px) {
      max-height: 100%;
      height: 100%;
      box-sizing: border-box;
    }

    @media (min-width: 992px) and (orientation: portrait) {
     
    }
  }

  .auth {
    height: 100%;
    max-height: 100%;
  }

  &_info {
    bottom: 10vw;
    left: 10vw;
    min-height: 10vw;
    min-width: 10vw;
    border: 1px solid gold;
    border-radius: 10px;
    padding: 15px;
  }

  &_game {
    .logo {
      width: 250px;
      height: 40px;
      margin-right: auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      @media (min-width: 992px) and (orientation: portrait) {
        display: none;
      }
    }
    @media (max-width: 568px) {
      padding: 0;
      background-color: #000;
    }
    @media (max-width: 992px) and (orientation: landscape) {
      padding: 0;
      background-color: #000;
    }
  }
}

.game-code {
  height: 100%;
  max-height: 100%;
  display: flex;
  background-color: #000;
  justify-content: center;
  // background-color: #13003c;
  iframe {
    max-width: 100vh;
  }
  @media (max-width: 568px) {
    height: 100vh;
    align-items: center;
    iframe {
      max-width: 100%;
      min-height: 100%;
    }
  }
  @media (max-width: 992px) and (orientation: landscape) {
    height: 100%;
    iframe {
      max-width: 100%;
      height: 100%;
    }
  }
}
