.profile {
  position: relative;
  margin: 10px auto;
  // width: 950px;
  width: 940px;
  font-family: var(--fontFamily);
  border-radius: 16px;

  .profile {
    margin-top: 100px;
    // height: 540px;
    height: 730px;
  }

  @media (min-width: 1771px) {
    margin-top: 100px;
    // height: 540px;
    height: 730px;
  }

  @media (max-width: 1770px) {
    margin-top: 100px;
    // height: 540px;
    height: 730px;
  }

  @media (max-width: 992px) {
    width: 95vw;
    margin-top: 100px;
  }


  @media (max-width: 578px) {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 1px;
  }


  &__wrapper {
    width: 100%;
    height: 100%;
    // max-width: 991px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    padding-top: 0;
    // border: 1px solid red;

    @media (max-width: 992px) {
      padding-top: 0;
      margin-top: 10px;
      width: 95vw;
    }

    @media (max-width: 578px) {
      flex-direction: column;
      margin-top: 0;
      padding: 0px;
      width: 100%;
    }

    .menuProfile {
      width: 300px;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        width: 45vw;
        padding: 0 10px;
        overflow-x: scroll;
      }

      @media (max-width: 660px) {
        width: 45vw;
      }

      @media (max-width: 578px) {
        width: 100%;
        padding: 0;
        max-height: 140px;
        background: #21443E;
      }

      .headerProfile {
        display: flex;
        // width: 300px;
        // height: 150px;
        padding: 64px 64px 32px 64px;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin: 0 auto;
        text-align: center;

        @media (max-width: 992px) {
          max-width: 200px;
          text-align: left;
        }

        @media (max-width: 578px) {
          display: none;
        }

        .title {
          // width: 272px;
          font-weight: 800;
          font-size: 40px;
          line-height: 55px;
          color: var(--profileTextColor);
          // text-transform: uppercase;

          @media (max-width: 992px) {
            font-size: 30px;
            line-height: 35px;
          }

          @media (max-width: 700px) {
            font-size: 24px;
            line-height: 28x;
          }

          @media (max-width: 660px) {
            font-size: 20px;
            line-height: 24x;
          }

          @media (max-width: 640px) {
            width: 180px;
          }

        }
      }

      .containerMenu {
        width: 100%;
        height: auto;

        .mobile {

          @media (min-width: 579px) {
            display: none;
          }

          @media (max-width: 578px) {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            padding: 10px;

          }

          .logo {
            max-width: 130px;
            height: 80%;

            img {
              max-width: 100%;
              height: auto;
            }

          }

          .AccountButton {
            .cash {
              text-shadow: 0px 0px 16px rgba(60, 235, 118, 0.5), 0px 0px 32px rgba(60, 235, 118, 0.5);
            }

            .text {
              .user_ico {
                filter: drop-shadow(0px 0px 16px rgba(60, 235, 118, 0.9));
              }
            }
          }
        }

        .itemMenu {
          border-top: 1px solid rgba(0, 0, 0, 0.2);
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);

          @media (max-width: 578px) {
            display: flex;
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;
            max-width: 950px;
            gap: 20px;
            padding: 0 20px;

            border-top: 1px solid rgba(0, 0, 0, 0.2);
            border-bottom: none;
          }

          .linkMenu {
            // width: 100%;
            // height: 70px;
            position: relative;
            display: flex;
            align-items: center;
            // margin: 0 10px;
            // padding: 0 20px;
            padding: 32px 64px 32px 64px;
            margin-bottom: 0;
            // border-radius: 10px;
            transition: .2s;
            cursor: pointer;

            span {
              color: var(--profileSectionTextColor);
              font-size: 20x;
              line-height: 24px;
              white-space: nowrap;
              font-weight: 700;
              // line-height: 30px;
              opacity: 1;

              @media (max-width: 680px) {
                font-size: 13px;
              }
            }

            .ico {
              // width: 25px;
              width: 24px;
              height: 24px;
              margin: 0 10px;
              // color: #03C071;
              color: rgb(67, 241, 116);

              @media (max-width: 640px) {
                width: 20px;
              }

              @media (max-width: 600px) {
                width: 20px;
              }

              @media (max-width: 578px) {
                display: none;
              }

              img {
                width: 100%;
                min-width: 24px;
              }

            }

            @media (min-width: 992px) {
              &:hover {
                &::after {
                  opacity: 1;
                }

                span {
                  // opacity: 1;
                  transition: 0.2s;
                }
              }
            }

            @media (max-width: 640px) {
              margin: 0;
              padding: 0;
              text-align: left;
            }

            @media (max-width: 480px) {
              height: 40px;
            }

            &:last-of-type {
              margin-right: 0;
            }

            &[data-active='true'] {
              &::after {
                opacity: 1;
              }

              span {
                color: rgb(67, 241, 116);
                font-size: 20;
                line-height: 24px;
                white-space: nowrap;
                font-weight: 700;

                @media (max-width: 578px) {
                  color: rgb(255, 255, 255);
                  text-shadow: 0px 0px 16px rgba(60, 235, 118, 0.5), 0px 0px 32px rgba(60, 235, 118, 0.5);
                }

              }
            }

            // &::after {
            //   content: "";
            //   display: block;
            //   position: absolute;
            //   left: 0;
            //   border-radius: 1px;
            //   top: 0;
            //   width: 100%;
            //   height: 100%;
            //   // background: rgba(0, 0, 0, 0.1);
            //   // background: rgba(0, 0, 0, 0.2);

            //   box-shadow: none;
            //   opacity: 0;
            //   transition: .3s opacity ease;

            //   @media (max-width: 578px) {
            //     background: none;
            //     position: relative;
            //     left: initial;
            //   }
            // }
          }

          .linkMenu[data-active='true'] {
            background: rgba(0, 0, 0, 0.2);

            @media (max-width: 480px) {
              border-bottom: 2px solid #03C071;
              border-radius: initial;
            }
          }
        }

        .itemMenu::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .contentTab {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(0deg, #122A27, #122A27);

      @media (max-width: 992px) {
        max-height: unset;
        padding: 10px 10px 0 10px;
      }

      @media (max-width: 992px) and (orientation: landscape) {
        max-width: 582px;
        padding: 20px 0 0 0;
      }

      @media (max-width: 578px) {
        padding-top: 20px;
        padding-bottom: 150px;
      }

      .titleTab {
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: var(--profileTextColor);
      }

      .controls {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 100%;
        padding-top: 10px;
        padding-right: 10px;

        .containerButtonLogout {
          display: flex;
          align-items: center;
          width: 150px;
          height: 50px;

          .Button {
            background: none;
            justify-content: flex-start;


            &.ButtonLogout {
              min-width: auto;
              opacity: 0.3;
              color: rgb(255, 255, 255);

              span {
                padding: 0 10px;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                order: 2;
              }

              img {
                width: 13px;
                height: 20px;
                order: 1;
              }

              @media (max-width: 578px) {
                padding: 5px 10px;
                opacity: 0.9;
                color: rgb(255, 255, 255);
                border: 1px solid rgb(255, 255, 255);
                border-radius: 8px;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
              }
            }
          }
        }

        @media (max-width: 992px) and (orientation: portrait) {
          width: 100%;
        }

        .Button {
          border: none;
          opacity: 0.5;
          background: none;

          &:nth-last-child(1) {
            margin-right: 0;
          }
        }

        .ButtonClose {
          &::after {
            position: absolute;
            z-index: 4;
            content: "";
            width: 30px;
            height: 30px;
            background-image: url("./assets/close.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 45%;
          }

          svg {
            opacity: 0;
          }
        }

        .ButtonSound {
          margin: 0 10px;

          svg {
            color: rgb(255, 255, 255);
            opacity: 0.5;
          }
        }
      }

      .tab {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        @media (max-width: 992px) {
          max-height: unset;
          padding-right: 0;
        }

        &::-webkit-scrollbar {
          width: 5px;
          background-color: transparent;
        }

        &::-webkit-scrollbar-track {
          background: var(--profileScrollbarBackground);
          width: 7px;
          border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: var(--profileScrollbarDragBackground);
        }

        .TransactionHistory {

          .title {
            display: none;
          }

          form.settings {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 20px 0 0 40px;
            // border: 1px solid red;

            .block {
              width: 50%;
              display: flex;
              justify-content: flex-start;
              margin-bottom: 30px;
              position: relative;

              @media (max-width: 770px) {
                flex-wrap: wrap;
              }

              .title {
                display: block;
                width: 100%;
                font-size: 18px;
                line-height: 28px;
                font-weight: 600;
                color: #fff;
                margin-bottom: 15px;
                text-transform: capitalize;
              }

              .input-date {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: 10px;
                padding-right: 5px;
                background: var(--gamesHistoryInputsBackground);
                border-radius: 4px;
                // height: 40px;
                height: 52px;
                position: relative;
                margin-right: 15px;
                max-width: 140px !important;

                @media (max-width: 770px) {
                  padding-left: 0;
                }

                &:last-child {
                  margin-right: 0;
                }

                .date,
                .time {
                  background: transparent;
                  font-family: "Fira Sans", sans-serif;
                  font-size: 16px;
                  font-weight: 500;
                  border: none;
                  color: var(--gamesHistoryInputsTextColor);
                  position: relative;
                  padding-right: 25px;

                  &::-webkit-calendar-picker-indicator {
                    // filter: invert(1);
                    // opacity: 0.3;
                    opacity: 0;
                  }
                }

                .date::after {
                  position: absolute;
                  right: 0;
                  z-index: 3;
                  content: url('./assets/calendar.svg');
                  opacity: 0.3;
                  pointer-events: none;
                }

                .time:after {
                  position: absolute;
                  right: 0;
                  z-index: 3;
                  content: url('./assets/time.svg');
                  opacity: 0.3;
                  pointer-events: none;
                }

              }
            }

            .show-history {
              border-radius: 8px;
              text-shadow: 0px 0px 1px var(--gamesHistoryButtonTextColor);
              font-weight: bold;
              color: var(--gamesHistoryButtonTextColor);
              box-shadow: var(--gamesHistoryButtonShadow);
              background: var(--gamesHistoryButtonBackground);
              margin: 0 10px 0 0;

              &:hover {
                box-shadow: none;

                &::after {
                  opacity: 1;
                }
              }

              &::after {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 100px;
                position: absolute;
                top: 0;
                left: 0;
                opacity: .2;
                box-shadow: var(--gamesHistoryButtonLight);
                transition: .3s opacity ease;
              }
            }

            .operation {
              display: none;
            }
          }

          // display: flex;
          // align-items: center;
          // justify-content: flex-start;
          // flex-direction: column;
          // font-family: var(--fontFamily);
          // margin: 0 40px;

          // .title {
          //   display: none;
          // }

          // .settings {
          //   margin-top: 20px;

          //   .title {
          //     display: block;
          //     font-size: 18px;
          //     line-height: 28px;
          //     font-weight: 600;
          //     text-transform: capitalize;
          //   }

          //   .block {
          //     .input-date {
          //       background: rgba(0, 0, 0, 0.2);
          //       svg {
          //         width: 40%;
          //       }

          //       .date {
          //         font-size: 18px;
          //         line-height: 28px;
          //         font-weight: 600;
          //         color: rgb(255, 255, 255);
          //         opacity: 0.5;             
          //       }

          //       // .date::-webkit-calendar-picker-indicator{
          //       //   color: white;
          //       //   opacity: 1;
          //       //   background: url(./assets/calendar.svg) no-repeat right;
          //       //   background-size: contain;
          //       //   // right:0; padding:.539em .34em;
          //       // }
          //       .time {
          //         color: rgb(255, 255, 255);
          //         opacity: 0.5;
          //       }
          //     }

          //     // #date_start::-webkit-calendar-picker-indicator{
          //     //   color: #03C071;
          //     // }
          //   }

          //   .show-history {
          //     border-radius: 8px;
          //     text-shadow: 0px 0px 1px var(--gamesHistoryButtonTextColor);
          //     font-weight: bold;
          //     color: var(--gamesHistoryButtonTextColor);
          //     box-shadow: var(--gamesHistoryButtonShadow);
          //     background: var(--gamesHistoryButtonBackground);
          //     margin: 20px auto;

          //     &:hover {
          //       &::after {
          //         opacity: 1;
          //       }
          //     }

          //     &::after {
          //       content: "";
          //       display: block;
          //       width: 100%;
          //       height: 100%;
          //       border-radius: 100px;
          //       position: absolute;
          //       top: 0;
          //       left: 0;
          //       opacity: .2;
          //       box-shadow: var(--transactionsHistoryButtonLight);
          //       transition: .3s opacity ease;
          //     }

          //   }
          // }

          // .head {
          //   width: 100%;
          //   display: flex;
          //   align-items: center;
          //   justify-content: space-between;
          //   padding-top: 15px;
          //   padding-bottom: 10px;

          //   .title {
          //     color: #fff;
          //     // text-transform: uppercase;
          //     font-weight: 700;
          //     font-size: 18px;
          //   }

          //   .controls {
          //     display: flex;
          //     align-items: center;
          //     justify-content: flex-end;

          //     .refresh {
          //       color: #fff;
          //       display: flex;
          //       align-items: center;
          //       justify-content: center;
          //       cursor: pointer;

          //       svg {
          //         fill: #fff;
          //         width: 21px;
          //         height: 21px;
          //       }
          //     }

          //     .info {
          //       display: flex;
          //       align-items: center;
          //       justify-content: center;
          //       position: relative;

          //       svg {
          //         fill: #fff;
          //         width: 21px;
          //         height: 21px;
          //       }
          //     }
          //   }
          // }

          // .operation {
          //   display: none;
          // }
        }

        form[class="tab tab--password"] {
          .row {
            .title {
              font-size: 18px;
              line-height: 28px;
              font-weight: 700;
              text-transform: capitalize;
            }
          }
          .value > input {
            font-size: 18px;
            line-height: 28px;
            font-weight: 600;
          }
          
        }

        .GamesHistory {
          form.settings {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 20px 0 0 40px;
            // border: 1px solid red;

            .block {
              width: 50%;
              display: flex;
              justify-content: flex-start;
              margin-bottom: 30px;
              position: relative;

              @media (max-width: 770px) {
                flex-wrap: wrap;
              }

              .title {
                width: 100%;
                font-size: 18px;
                font-weight: 500;
                color: #fff;
                margin-bottom: 15px;
              }

              .input-date {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: 10px;
                padding-right: 5px;
                background: var(--gamesHistoryInputsBackground);
                border-radius: 4px;
                // height: 40px;

                height: 52px;                position: relative;
                margin-right: 15px;
                max-width: 140px !important;

                @media (max-width: 770px) {
                  padding-left: 0;
                }

                &:last-child {
                  margin-right: 0;
                }

                .date,
                .time {
                  background: transparent;
                  font-family: "Fira Sans", sans-serif;
                  font-size: 16px;
                  font-weight: 500;
                  border: none;
                  color: var(--gamesHistoryInputsTextColor);
                  position: relative;
                  padding-right: 25px;

                  &::-webkit-calendar-picker-indicator {
                    // filter: invert(1);
                    // opacity: 0.3;
                    opacity: 0;
                  }
                }

                .date::after {
                  position: absolute;
                  right: 0;
                  z-index: 3;
                  content: url('./assets/calendar.svg');
                  opacity: 0.3;
                  pointer-events: none;
                }

                .time:after {
                  position: absolute;
                  right: 0;
                  z-index: 3;
                  content: url('./assets/time.svg');
                  opacity: 0.3;
                  pointer-events: none;
                }

              }
            }

            .show-history {
              border-radius: 8px;
              text-shadow: 0px 0px 1px var(--gamesHistoryButtonTextColor);
              font-weight: bold;
              color: var(--gamesHistoryButtonTextColor);
              box-shadow: var(--gamesHistoryButtonShadow);
              background: var(--gamesHistoryButtonBackground);
              margin: 0 10px 0 0;

              &:hover {
                box-shadow: none;

                &::after {
                  opacity: 1;
                }
              }

              &::after {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 100px;
                position: absolute;
                top: 0;
                left: 0;
                opacity: .2;
                box-shadow: var(--gamesHistoryButtonLight);
                transition: .3s opacity ease;
              }
            }
          }
        }

        .qrcode {
          // margin-top: 20%;
          display: flex;
          width: 100%;
          height: 100%;
          align-self: center;
          padding: 0;
        }
      }
    }

    &__footer {
      color: var(--profileBottomTextColor);
      text-align: center;
      // text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      margin-top: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;
      padding: 15px 10px;
    }
  }

}


.profile::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

.profile::-webkit-scrollbar-track {
  background-color: #999;
}

.profile::-webkit-scrollbar-track-piece {
  background-color: rgba(255, 255, 255, 0.8);
}

.profile::-webkit-scrollbar-thumb {
  height: 30px;
  background-color: #03C071;
  border-radius: 30px;
}

.profile::-webkit-scrollbar-corner {
  background-color: #999;
}

@-moz-document url-prefix() {
  .profile {
    scrollbar-color: #03C071 rgba(255, 255, 255, 0.8) !important;
    scrollbar-width: thin !important;
  }
}