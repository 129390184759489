.search {
    position: relative;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-right: 20px;

    @media screen and (max-width: 920px) {
      margin-right: 0;
    }

    @media screen and (max-width: 820px) {
      width: 150px;
      height: 40px;
    }
    @media screen and (max-width: 800px) {
      width: 198px;
      height: 52px;
    }

    @media screen and (max-width: 480px) {
      width: 198px;
      height: 52px;
    }

    .clear {
        display: none;
        width: 2vw;
        height: 2vw;
        position: absolute;
        top: 10px;
        right: 10px;
        background-image: url(./images/close.svg);
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: center;
        background-size: 8px;
        border-radius: 10px;
        cursor: pointer;
        transition: .2s opacity ease;

        &:hover {
          opacity: 1;
        }

        &[data-visible='true'] {
          display: block;
        }
      }

    .search-input {
        width: 180px;
        height: 52px;
        margin: 0;
        padding: 14px 16px;
        color: #FFFFFF;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        font-family: 'Nunito Sans', sans-serif;
        opacity: 0.3;
        background: url(./images/Search.png) no-repeat 3px 1px;
        background-size: 24px 24px;
        background-position-x: 16px;
        background-position-y: center;

        &[data-open='true']{
            background-image: none;
          }

          &[data-open='false']{
            background-image: block;
          }

          @media screen and (max-width: 1024px) {
            width: 150px;
            height: 48px;
          }

            @media screen and (max-width: 980px) {
            height: 40px;
          }

          @media screen and (max-width: 800px) {
            width: 198px;
            height: 52px;
            background-size: 24px 24px;
            font-size: 20px;
            line-height: 24px;
            font-weight: 400;
          }

          @media screen and (max-width: 320px) {
            width: 170px;
            height: 52px;
          }
    }

    .search-input::placeholder {
        text-align: center;
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        opacity: .8;

        @media screen and (max-width: 1300px) {
          text-align: end;
          padding-right: 10px;
          font-size: 18px;
          line-height: 24px;
        }

        @media screen and (max-width: 991px) {
          padding-right: 10px;
          font-size: 16px;
          line-height: 20px;
        }


        @media screen and (max-width: 800px) {
          font-size: 18px;
          line-height: 24px;
        }

        @media screen and (max-width: 480px) {
          text-align: center;
          padding-right: 10px;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
        }
    }
}
