.ProvidersButton {
    width: 180px;
    height: 52px;
    border-radius: 8px;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
    border: 2px solid #03C071;
    background: linear-gradient(180deg, #43F174 0%, #03C071 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media screen and (max-width: 1300px) {
        width: 130px;
        height: 52px;
        font-weight: 800;
        font-size: 20px;
        line-height: 28px;
    }

    @media screen and (max-width: 1299px) {
      font-size: 16px;
      line-height: 20px;
    }

    @media screen and (max-width: 1024px) {
      height: 46px;
    }

    @media screen and (max-width: 980px) {
      width: auto;
      height: auto;
      padding: 7px;
      font-size: 14px;
      line-height: 20px;
    }

    @media screen and (max-width: 800px) {
      width: 180px;
      height: 52px;
      // padding: 5px;
      // font-size: 1.3vw;
      font-size: 20px;
      line-height: 28px;
  }

      @media screen and (max-width: 480px) {
        width: 121px;
        height: 52px;
        font-weight: 800;
        font-size: 15px;
        line-height: 20px;
      }
}