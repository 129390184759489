.GameCard {
  width: 9.4vw;
  height: 6vw;
  position: relative;
  margin: auto;
  border-radius: 14px;
  transition: 0.5s ease;
  position: relative;
  border-style: solid;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);

  &:hover {
    transform: scale(1.05);
    z-index: 2;
  }

  // уточнить максимальное разрешение экрана просмотра
  @media (min-width: 3840px) and (max-width: 3401px) {
    width: 9vw;
    height: 7vw;
  }

  @media (max-width: 3400px) {
    width: calc(9vw + 70px);
    height:calc(7vw + 10px);
  }


   @media (max-width: 2560px) {
    width: 14vw;
    height: 10vw;
  }

  // @media (max-width: 2650px) and (max-width: 2560px) {
  //   width: 14vw;
  //   height: 10vw;
  // }

  @media (max-width: 1920px) {
    width: 18.4vw;
    height: 14vw;
  }

  @media (max-width: 992px) and (orientation: landscape) {
    width: 25vw;
    height: 18vw;
  }

  @media (max-width: 576px) and (orientation: portrait) {
  width: 180px;
  height: 140px;
  }

  @media (max-width: 375px) and (orientation: portrait) {
    width: 160px;
    height: 120px;
    }

  @media (max-width: 325px) and (orientation: portrait) {
    width: 140px;
  }

  &:hover {
    &::after {
      opacity: .4;
    }

    .content {
      opacity: 1;
    }

    .freespin {
      animation: alarmPulse 1.2s;
    }

    .favorite {
      display: flex;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: .2s ease-in;
    position: relative;
    cursor: pointer;
  }

  .inner {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 14px;
    overflow: hidden;

    @media (max-width: 991px) {
      max-width: 364px;
      max-height: 270px;
      border-radius: 4px;
    }
  }


  .freespin {
    position: absolute;
    top: 1%;
    left: 1%;
    width: 17%;
    height: 35px;
    background-image: url("./assets/freespin.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 0;
    pointer-events: none;
    z-index: 1;
  }

  .ButtonFavorite {
    position: absolute;
    width: 1.6vw;
    height: 1.6vw;
    top: 0.5vw;
    right: .5vw;

    @media (max-width: 992px) {
      width: 20px;
      height: 20px;
      right: 5px;
      top: 5px;
    }
  }

}

@keyframes alarmPulse {
  0% {
    transform: rotate(-4deg) scale(1)
  }

  10% {
    transform: scale(1.025) rotate(4deg)
  }

  25% {
    transform: scale(1.050) rotate(-4deg)
  }

  30% {
    transform: scale(1.075) rotate(4deg)
  }

  40% {
    transform: scale(1.1) rotate(-4deg)
  }

  50% {
    transform: scale(1.125) rotate(4deg)
  }

  60% {
    transform: scale(1.1) rotate(-4deg)
  }

  70% {
    transform: scale(1.075) rotate(4deg)
  }

  80% {
    transform: scale(1.050) rotate(-4deg)
  }

  90% {
    transform: scale(1.025) rotate(4deg)
  }

  100% {
    transform: rotate(0deg) scale(1)
  }
}

@keyframes rotateGameLoader {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}