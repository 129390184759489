.code {
  display: inline-flex;
  justify-content: center;
  padding: 20px;
  position: relative;
  margin: auto;
  min-width: 308px;
  min-height: 308px;
  border-radius: 10px;
  background-color: transparent ;

  .image {
    width: 100%;
  }

  .preloader {
    position: absolute;
    height: 100%;
    
    .loader .image {
      width: 20px;
      height: 20px;
    }
  }
}
