.menu {
    position: relative;
    z-index: 25;
    display: flex;
    justify-content: space-around;
    max-width: 1550px;
    background: rgb(23, 74, 68);
    transition: .2s opacity ease;


    &[data-visible='true'] {
        opacity: 1;
    }

    &[data-visible='false'] {
        opacity: 0;
    }

    @media screen and (min-width: 1025px) {
        margin: -30px auto 50px auto;
        padding: 24px 24px 24px 64px;
        background: rgb(23, 74, 68);
    }

    @media screen and (max-width: 1024px) {
        margin: -50px auto 50px auto;
        padding: 24px 24px 24px 64px;
    }

    @media screen and (max-width: 800px) {
        // контейнер меню
        flex-direction: column;
        margin: 0 auto;
        background: transparent;
        box-shadow:  none;
        border-radius: 1px;
        padding: 0;
    }

    .containerTypesButtons {

        @media screen and (max-width: 800px) {
            order: 2;
        }

    }

    .searchPanel {
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 920px) {
            gap: 10px;
        }

        @media screen and (max-width: 800px) {
            margin-right: 0;
            padding: 20px 10px;
            order: 1;
            justify-content: space-evenly;
            background: rgb(33, 68, 62);
            background-size: 100%;
            box-shadow: 0px 4px 32px rgba(11, 21, 20, 0.2);
            border-top: 1px solid rgba(0, 0, 0, 0.16);
        }

    }
}
