.tab--password {
  padding-left: 45px;
  padding-right: 20px;
  font-family: "Fira Sans", sans-serif;

  .input {
    margin-bottom: 0;

    @media (max-width: 567px) {
      font-size: 16px;
    }
  }

  .row {
    display: flex;
    margin: 20px 0;
    justify-content: space-between;

    .value {
      margin-right: 30px;
      width: 50%;

      @media (max-width: 567px) {
        width: 65%;
      }
    }

    .title {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      color: var(--profileTextColor);
      text-transform: uppercase;

      @media (max-width: 567px) {
        width: 35%;
        font-size: 14px;
      }
    }
  }

  .change-password {
    width: 139px;
    height: 32px;
    padding: 6px 16px;
    border: 1px solid #03C071;
    border-radius: 8px;
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    background: linear-gradient(180deg, #43F174 33.93%, #03C071 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    cursor: pointer;
  }

  .message {
    font-weight: 600;
    font-size: 16px;
    text-align: center;

    &_error {
      color: var(--errorTextColor);
    }

    &_success {
      color: var(--successTextColor);
    }
  }
}